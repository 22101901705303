<template>
  <div id="userArea">

    <div v-if="errorMessage || errorLog">
      <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />
    </div>

    <!-- header -->
    <div class="container-fluid bg-white">
      <div class="row">
        <!-- Col Start -->
        <div class="col-8 col-md-2 order-0 order-md-0">
          <!-- Logo -->
          <div id="branding" class="branding branding-light sticky-top" @click="goToHome"></div>
        </div>

        <!-- Col Start -->
        <div class="col-4 col-md-10 order-0 order-md-0 d-flex d-md-block  align-items-center justify-content-center">
          <!-- User Area Icon-->
          <div class="mt-0 mt-lg-5 me-0">
            <!-- Top Tools / User Area / Cart View -->
            <div class="header-options-wrapp">

              <!-- User Area Icon-->
              <div class="footer-cart" @click="goToCart">
                <div class="bg-white rounded-circle">
                  <div class="cart-item-count">
                    {{ $store.state.user.shoppingCart?.cartItems ? $store.state.user.shoppingCart.cartItems.length : 0 }}
                  </div>
                  <img src="products/editable/cart-svgrepo-com-b.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Contents -->
    <div class="container-fluid">
      <div class="row">

        <!-- Side Menu -->
        <div id="container-UserOptions" class="col-12 col-md-3">
          <div class="side-options-wrap">
            <div @click="showSection('viewForm')" :class="viewForm ? 'active' : ''"> <i class="fa fa-user-alt"></i>{{
              lib.txt("userProfile") }} </div>
            <div @click="showSection('viewFavorites')" :class="viewFavorites ? 'active' : ''"> <i
                class="fa fa-heart"></i>{{ lib.txt("favorites") }} </div>
            <div @click="showSection('viewOrders'), getPreviousOrders()" :class="viewOrders ? 'active' : ''"> <i
                class="fa fa-shopping-bag"></i>{{ lib.txt("previousOrders") }} </div>
          </div>

          <transition name="custom-classes" enter-active-class="animate__animated animate__fadeInLeft animate__faster"
            leave-active-class="animate__animated animate__fadeOutLeft animate__faster">

            <div id="viewOrders" v-if="viewOrders" class="order-filter-wrapp">
              <div class="order-filter-container">
                <div class="border-bottom font-title mb-3"><b>{{ lib.txt("filter") }}</b></div>
                <!--<div class="text-center">{{lib.txt(texts.byYear)}}</div>-->
                <div class="mb-3 visually-hidden">
                  <div
                    class="btn-round-group d-flex flex-wrap justify-content-center font-small gap-2 justify-content-center my-2">
                    <button class="btn-round-light" :class="filteredYear == new Date().getFullYear() + 2 ? 'active' : ''"
                      @click="updateSelectedYear(new Date().getFullYear() + 2)"> {{ new Date().getFullYear() + 2 }}
                    </button>
                    <button class="btn-round-light" :class="filteredYear == new Date().getFullYear() + 1 ? 'active' : ''"
                      @click="updateSelectedYear(new Date().getFullYear() + 1)"> {{ new Date().getFullYear() + 1 }}
                    </button>
                    <button class="btn-round-light" :class="filteredYear == new Date().getFullYear() ? 'active' : ''"
                      @click="updateSelectedYear(new Date().getFullYear())"> {{ new Date().getFullYear() }} </button>

                  </div>
                  <!--<div class=" d-flex flex-wrap justify-content-center">
                    <button class="btn-round-light form-references-ordered-button d-block m-0" @click="getPreviousOrders"><i class="fa fa-filter me-2"></i> Filter by {{filteredYear}}</button></div>-->
                </div>
                <div class="text-center">{{ lib.txt("byReference") }}</div>

                <div class="d-flex flex-column gap-2 align-items-center">

                  <div class="d-flex container-search">
                    <input class="form-input-search" :placeholder="lib.txt('search')" v-model="filteredName">
                    <div>
                      <button class="form-search-button" @click="getPreviousOrders"><i class="fa fa-search me-2"></i>{{
                        lib.txt("search") }}</button>
                    </div>
                  </div>

                  <!--<div class="">
                    <button class="btn-round-light form-references-ordered-button" @click="showAllOrdered"><i class="fa fa-filter me-2"></i>{{lib.txt(texts.searchAll)}}</button>
                  </div>-->
                </div>

              </div>
            </div>
          </transition>
        </div>

        <!-- User Area Related -->
        <div id="container-UserArea" class="col-12 col-md-8 shadow rounded bg-white">

          <!-- User Area Header -->
          <div class="info-UserArea d-flex align-items-center">
            <div class="icon-user-area-x2 me-3">
              <i class="fa fa-user"></i>
            </div>

            <div class="me-3">
              <div>
                <b>{{ lib.txt("customerNr") }}</b>
                <div class="info-userNR">{{ $store.state.user.number }}</div>
              </div>
            </div>

            <div class="flex-grow-1"></div>

            <div class="icon-user-logOut-x2" @click="logout">
              <div><i class="fa fa-sign-out-alt"></i></div>
              <span>{{ lib.txt("logout") }}</span>
            </div>
          </div>

          <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__hide" mode="out-in">
            <!-- Form User Area -->

            <div v-if="viewForm" class="form-store">
              <div class="form-store-group">
                <div class="form-store-container"> <label>{{ lib.txt("firstName") }}</label>
                  <input placeholder="Customer" :value="$store.state.user.firstName" disabled />
                </div>

                <div class="form-store-container"> <label>{{ lib.txt("lastName") }}</label>
                  <input placeholder="Customer" :value="$store.state.user.surname" disabled />
                </div>
              </div>

              <div class="form-store-group">
                <div class="form-store-container"> <label>{{ lib.txt("email") }}</label>
                  <input placeholder="Customer" :value="$store.state.user.email" disabled />
                </div>

                <div class="form-store-container"> <label>{{ lib.txt("phoneNumber") }}</label>
                  <input placeholder="Customer" :value="$store.state.user.phoneNumber" disabled />
                </div>

              </div>

              <div class="form-store-group">
                <div class="form-store-container"> <label>{{ lib.txt("company") }}</label> <input
                    placeholder="Company, lda" :value="$store.state.user.company" disabled /></div>
                <div class="form-store-container"> <label>{{ lib.txt("location") }}</label> <input placeholder="Location"
                    :value="$store.state.user.location" disabled /></div>
                <div class="form-store-container"> <label>{{ lib.txt("postalCode") }}</label> <input
                    placeholder="0000-000" :value="$store.state.user.postalCode" disabled /></div>
              </div>

              <hr>

              <!-- Change Password Header -->
              <div class="info-UserArea d-flex align-items-center border-0 p-0">

                <span class="fa-stack fa-2x m-0 p-0 my-3 position-relative">
                  <i class="fa fa-shield fa-stack-2x color-baby-blue"></i>
                  <i class="fa fa-key fa-stack-1x fa-inverse"></i>
                </span>

                <div class="me-3">
                  <div>
                    <b>Actualizar Password</b>
                  </div>
                </div>

                <div class="flex-grow-1"></div>

              </div>

              <!-- Change Password Form -->
              <div class="bck-System px-2 py-2 form-store-dark position-relative">

                <transition name="custom-classes" enter-active-class="animate__animated animate__fadeIn">

                  <div class="d-flex flex-column align-items-center py-5" v-if="wasPasswordUpdated">

                    <!-- Check Badge-->
                    <div class="bg-success fa fa-check p-2 m-3 rounded-pill fa-2x "> </div>

                    <!-- Sucess Change Password Text -->
                    <div class="font-01 font-weight-bold py-3 color-blue">
                      <!-- A sua password foi actualizada com sucesso.
                      No próximo login estará em efeito a sua nova password. -->
                      {{ lib.txt('pwChange_Modificado') }}
                    </div>

                  </div>
                </transition>

                <div v-if="!wasPasswordUpdated">

                  <!-- Is loading section -->
                  <div id="isUpdatingPassword" class="isLoading-Zone" v-if="isUpdatingPassword">
                    <div class='loader-wrapper'>
                      <div class='loader text-center'>
                        <div class='spinner'></div>
                        <p>{{ lib.txt("info_Updating") }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="form-store-group">
                    <div class="form-store-container">
                      <!--Current Password-->
                      <label>{{lib.txt('current_password')}}</label>
                      <input type="password" id="password" v-model.trim="oldpassword" @keyup="passwordChangeControl()" />
                    </div>
                  </div>

                  <div class="form-store-group" :class="{ disabled: oldpassword === '' }">

                    <div class="form-store-container position-relative">
                      <!-- New Password -->
                      <label>{{lib.txt('new_password')}}</label>
                      <input type="password" id="password-step-01" v-model.trim="password_step_01"
                        @keyup="passwordChangeControl()" />

                      <!-- Compare Status -->
                      <div class="password-compare-status d-flex-centered" style="top:18px; right: -44px">

                        <div v-if="!password_match" class="icon-round-x1  m-0 p-0 bg-danger" style="z-index: 1;">
                          <i class="fa fa-times text-white"></i>
                        </div>

                        <div v-if="password_match" class="icon-round-x1  m-0 p-0 bck-babyBlue" style="z-index: 1;">
                          <i class="fa fa-check text-white"></i>
                        </div>

                      </div>

                    </div>

                    <div class="form-store-container" :class="{ disabled: password_step_01 === '' }">
                       <!-- Confirm Password -->
                      <label>{{lib.txt('password_confirm')}}</label>
                      <input type="password" id="password-step-02" v-model.trim="password_step_02"
                        @keyup="passwordChangeControl()" />
                    </div>

                  </div>

                  <!-- Strength Chart -->
                  <div class="d-flex-centered">
                    <div class="rounded-4 row w-100 py-2 d-flex-centered border border-2">
                      <div class="col-3">
                        <div class="font-01 font-weight-bold text-black text-right">
                          <!-- Segurança:-->
                        {{ lib.txt('pwChange_Seguranca') }} {{ passwordStrengthMessage }} %</div>
                      </div>
                      <div class="col">
                        <div class="w-100 position-relative">
                          <div class="password-strength-meter" :class="{ 'weak-password': passwordStrengthScore <= 40 }"
                            style="background-color: #99a6b9;">
                            <div :style="{ width: passwordStrengthScore + '%' }"></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                         <!-- Segurança Rules -->
                        <div class="text-note font-01 text-center my-2 text-black-50">
                          <div v-html="lib.txt('pwChange_minimum')"></div>
                          <div class="font-small" v-html="lib.txt('pwChange_Rules')">
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  

                  <!-- errorMessagePassword -->
                  <div class="d-flex-centered">
                    <div class="font-01 badge bg-danger p-3 mt-3 w-75 font-small">{{ errorMessagePassword }}</div>
                  </div>

                  <!-- Finalizar Update Password-->
                  <div class="form-store-group d-flex-centered" :class="{ disabled: !password_match }">
                    
                    <div class="bck-babyBlue button-select shadow text-center mt-3 w-50"
                      @click="updateUserAccountPassword()">
                      {{lib.txt('btn_Finalizar')}} <i class="fa fa-chevron-right ms-2"></i>
                    </div>
                  </div>
     
                </div>

              </div>

            </div> 
        
          </Transition>

          <!-- Favorites -->

          <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__hide" mode="out-in">
            <div v-if="viewFavorites" class="store-favorites">
              <!-- No Favorite INFO -->
              <div v-if="!$store.state.user.favorites || $store.state.user.favorites.length == 0"
                class="empty-item-notify w-100">
                <img src="products/editable/magnifying-glass-svgrepo-com.svg" alt="" style="width: 100px;" />
                {{ lib.txt("noFavorites") }}<b>{{ lib.txt("favorites") }}</b>
              </div>

              <!-- Is loading section -->
              <div v-if="isLoading" class="isLoading-Zone">
                <div class="loader-wrapper">
                  <div class="loader text-center">
                    <div class=spinner></div>
                    <p>{{ lib.txt("favoriteRemoving") }}.</p>
                  </div>
                </div>
              </div>

              <template v-for="f in $store.state.user.favorites" :key="f">
                <div class="favorite-wrapper">
                  <div class="favorite-container" :style="getProductImageUrl(f)">
                    <div class="favorite-remove" @click="removeFromFavorites(f)"> <i class="fa fa-heart-broken"></i>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </Transition>

          <!-- Orders -->
          <Transition name="custom-classes" enter-active-class="animate__animated animate__slideInUp animate__faster"
            leave-active-class="animate__animated animate__hide" mode="out-in">
            <div v-if="viewOrders" class="store-favorites">
              <!-- Is loading section -->
              <div v-if="isLoading" class="isLoading-Zone">
                <div class="loader-wrapper">
                  <div class="loader text-center">
                    <div class=spinner></div>
                    <p>{{ lib.txt("retrievingOrders") }}</p>
                  </div>
                </div>
              </div>



              <!-- no Orders Info -->
              <div v-if="previousOrders.length == 0" class="empty-item-notify w-100">
                <img src="products/editable/package-box-svgrepo-com.svg" alt="" style="width: 100px;" />
                {{ lib.txt("noResults") }}
              </div>

              <!-- Order Repeater start -->
              <div class="orders-wrapper">
                <template v-for="n in previousOrders" :key="n">
                  <div class="order-container py-3">

                    <!-- Order Info States and options-->
                    <div v-bind:id="'order-info-container-' + n.id" class="order-info-container"
                      :class="{ 'disabled opacity-25': n.state == 4 }">

                      <div class="border border-1 px-3 py-2 rounded-5">
                        <b>{{ n.number }}</b>
                      </div>

                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 5"> {{
                        lib.txt("processingDelivery") }} <i class="fa fa-circle text-warning"></i></div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 6"> {{ lib.txt("sentDelivery") }}
                        <i class="fa fa-circle text-success"></i>
                      </div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 8"> {{ lib.txt("finishedDelivery")
                      }} <i class="fa fa-circle text-secondary"></i> </div>

                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 7"> {{ lib.txt("receivedDelivery")
                      }} <i class="fa fa-circle text-secondary"></i> </div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 4"> {{ lib.txt("canceledDelivery")
                      }} <i class="fa fa-circle text-warning"></i> </div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 3"> {{ lib.txt("inactiveDelivery")
                      }} <i class="fa fa-circle text-warning"></i> </div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 2"> {{ lib.txt("activeDelivery")
                      }} <i class="fa fa-circle text-secondary"></i> </div>
                      <div class="border border-1 px-3 py-2 rounded-5" v-if="n.state == 1"> {{ lib.txt("pendingDelivery")
                      }} <i class="fa fa-circle text-secondary"></i> </div>

                      <!-- <div class="order-date"> <i class="fa fa-calendar me-2"></i><b>{{ n.products[0].date }}</b> </div> -->

                      <div class="order-price">{{ lib.getFormatedCurrency(n.totalPrice) }}</div>

                      <div class="flex-grow-1"></div>

                      <!-- Only Show Button if not canceled or already delivered -->
                      <!--<div v-if="n.state !== 4 && n.state !== 6"
                                 @click="cancelOrderUI(n.id)"
                                 v-bind:id="'cancel-btn-' + n.id"
                                 class="border border-1 px-3 py-2 rounded-5 order-cancel">
                                <i class="fa fa-trash mr-2"></i><b>Cancel this Order</b>
                            </div>-->

                    </div>


                    <!-- Order Photos -->
                    <div class="container-fluid mb-5">
                      <div class="row g-0">
                        <div class="col-12 col-md">

                          <div class="order-image-container">

                            <template v-for="p in n.products" :key="p">
                              <!--{{p.photoMainPath}}-->
                              <div class="order-image shadow" v-if="p.photoMainPath"
                                :style="{ backgroundImage: `url(${buildPath(p.photoMainPath)})` }">

                                <!--<img :src="buildPath(p.photoMainPath)"  />-->
                                <!--<img v-if="p.products[0]?.photoMainPath" :src="buildPath(n.products[0].photoMainPath)" />-->

                              </div>
                            </template>

                          </div>

                        </div>

                        <!-- CANCEL ORDER FORM START -->
                        <transition name="custom-classes"
                          enter-active-class="animate__animated animate__bounceIn animate__faster"
                          leave-active-class="animate__animated animate__bounceOut animate__faster">


                          <div v-bind:id="'form-cancelOrder-' + n.id"
                            class="col col-md-5 animate__animated animate__bounceIn animate__faster" style="display:none">

                            <!-- Is loading section -->
                            <div v-bind:id="'form-LoadingStatus-' + n.id" class="isLoading-Zone" style="display:none">
                              <div class="loader-wrapper">
                                <div class="loader text-center">
                                  <div class=spinner></div>
                                  <p>{{ lib.txt("loadingMessageSubmit") }}.</p>
                                </div>
                              </div>
                            </div>

                            <!-- v-if="showFormCancelWindow" -->
                            <!-- Order Cancel Form -->

                            <div
                              class="form-cancel-order border p-4 p-md-5 mt-3 mt-md-0 rounded-2 shadow position-relative">

                              <img class="order-cancel-icon" src="products/editable/cart-svgrepo-com.svg" />

                              <div v-bind:id="'form-showFormCancelOrder-' + n.id" style="display:block">

                                <img class="order-cancel-close" src="products/editable/close-svgrepo-com.svg"
                                  @click="cancelOrderUI(n.id)" />

                                <div class="py-4">
                                  <!-- Need to cancel your order ? -->
                                  <!-- Your order will be canceled promptly as per your request -->
                                  <h3 class="font-weight-bold text-black">{{ lib.txt("orderNeedToCancel01") }}</h3>
                                  <h7 class="text-black-50">{{ lib.txt("orderNeedToCancel02") }}</h7>
                                </div>

                                <!-- TextArea + Submit -->
                                <div v-if="orderFormGroup">
                                  <!-- Please provide us a description for why are you cancelling this order.-->
                                  <textarea v-bind:id="'cancel-textArea-' + n.id" @input="checkTextAreaValid(n)"
                                    class="order-textarea item-shadow"
                                    :placeholder="lib.txt('orderNeedToCancel03')"></textarea>

                                  <div @click="submitOrderCancel(n)" class="order-cancel-Submit disabled"
                                    v-bind:id="'submit-form-' + n.id">

                                    <!-- Cancel Submit Button -->
                                    <!-- Cancel order now -->
                                    <div id="btn-send-CancelOrder" class="button-select bck-babyBlue">
                                      <div class="label">
                                        {{ lib.txt("orderCancelBTN") }}
                                        <i class="fa fa-chevron-right"></i>
                                      </div>
                                      <div class="button-shade"></div>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              <div v-bind:id="'showFormCancelReply-' + n.id" style="display:none" class="">
                                <!-- This order was cancelled!-->
                                <!-- If you have any other questions concerning our products, please advise with us.-->
                                <div class="pt-4">
                                  <h3 class="font-weight-bold color-orange"> {{ lib.txt("orderNeedToCancel04") }}</h3>
                                  <h7 class="text-black-50">{{ lib.txt("orderNeedToCancel05") }}</h7>
                                </div>

                              </div>
                            </div>

                          </div>

                        </transition>


                        <!-- CANCEL ORDER SHOP INFORMATION START -->
                        <div class="col-12 col-md-5 " v-if="n.state == 4 && !n.canceledNow">

                          <!-- Order Cancel Form -->
                          <div
                            class="form-cancel-order border p-4 p-md-5 mt-3 mt-md-0 rounded-2 shadow position-relative">

                            <img class="order-cancel-icon" src="products/editable/cart-svgrepo-com.svg" />
                            <!-- This order was cancelled! -->
                            <!-- If you have any other questions concerning our products, please advise with us.-->
                            <div class="">
                              <div class="pt-4">
                                <h3 class="font-weight-bold color-orange">
                                  {{ lib.txt("orderNeedToCancel04") }}
                                </h3>
                                <h7 class="text-black-50">
                                  {{ lib.txt("orderNeedToCancel05") }}
                                </h7>
                              </div>

                              <!-- Store response-->
                              <!--<div class="" v-if="n.obs">
                                                <hr />
                                                <div class="align-items-center bck-babyBlue d-flex py-3 rounded-2">
                                                    <div> <i class="fa fa-message font-xl px-4"></i> </div>
                                                    <div>
                                                        {{n.obs ?? ""}}
                                                    </div>
                                                </div>
                                            </div>-->
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>

                  </div>
                </template>
              </div>


            </div>
          </Transition>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ErrorPopup from './PopUpMsg';
import axios from "axios";
import router from "../router";
import lib from "../common/library";
import global from "../common/global";
import texts from "../common/translation"
import Footer from './Footer';

export default {
  name: "Categories",
  components: { ErrorPopup, Footer },
  data() {
    return {
      audit: global.audit,
      buildPath: global.buildPath,
      texts,
      lib,
      global,
      viewOrders: false,
      viewOrdersOptions: false,
      viewForm: true,
      viewFavorites: false,
      previousOrders: [],
      filteredYear: 0,
      filteredName: "",
      isLoading: false,
      errorMessage: '', //errorBox
      errorLog: '', //errorBox

      showFormCancelWindow: false, //show the Form BOX
      showFormCancelOrder: true, //Show the Form Text Area
      showFormCancelReply: false, //Show after the form is sent
      orderFormGroup: true,

      //Password Change
      isUpdatingPassword: false, // UI logic
      wasPasswordUpdated: false, // UI logic
      oldpassword: '',
      password_step_01: '',
      password_step_02: '',
      password_match: false,
      errorMessagePassword: '',

      //Password Strength
      passwordStrength: { minLength: 8, hasUpperCase: true, hasLowerCase: true, hasNumber: true, hasSpecialChar: true, },
      passwordStrengthScore: 0,
    };
  },
  methods: {
    async getPreviousOrders() {
      lib.write(this.$store.state.user.favorites)
      //this.scrollTo('#viewOrders') //for a friendly mobile experience
      this.isLoading = true
      let r = await axios.get(
        `/api/Business/GetShopPurchaseOrders`,
        {
          params: {
            customerId: this.$store.state.user.customerId,
            year: this.filteredYear,
            referenceName: this.filteredName,
            languageId: this.$store.state.selectedLanguageId
          }
        }
      );

      if (r?.data?.success) {
        lib.write("\n\n[previous orders response]")
        lib.write(r.data.responseData);
        this.errorLog = r.data.responseData //for the log PopUP
        this.previousOrders = r.data.responseData.list
        this.isLoading = false
        this.audit("Listagem de Encomendas por:", this.filteredYear);
      }
      else {
        let headerError = "Error getting previous orders for customerId: " + this.$store.state.user.customerId + " and year: " + this.filteredYear + " and referenceName: " + this.filteredName + " Detail: ";
        this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
      }
    },

    //Start and Prepare the CancelOrder Interface
    cancelOrderUI(id) {
      //this.showFormCancelWindow = !this.showFormCancelWindow, 
      //this.showFormCancelOrder = true;  
      //this.showFormCancelReply = false;

      let formID = "form-cancelOrder-" + id; //tableID in dom

      let x = document.getElementById(formID);

      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.classList.add(
          "animate__bounceIn",
          "animate__faster"
        );
        x.style.display = "none";
      }

      this.scrollTo("form-cancelOrder-" + id);
    },

    //Check the textArea for input, and enable things.
    checkTextAreaValid(n) {
      let id = n.id;
      //alert(id)
      const textarea = document.getElementById("cancel-textArea-" + id).value;
      const submitformButton = document.getElementById("submit-form-" + id);

      n.reason = textarea;
      //Checks if textarea is empty, disable / enable submit button
      if (textarea) { submitformButton.classList.remove("disabled"); } else { submitformButton.classList.add("disabled"); }
    },

    //
    async submitOrderCancel(n) {
      let id = n.id;
      // this.showFormCancelReply = true;
      //this.showFormCancelOrder = false
      //alert(id)

      //when cancel submit is sent, disable the cancel order button.
      let mTargetBtn = document.getElementById("cancel-btn-" + id);
      let mTargetForm = document.getElementById("form-showFormCancelOrder-" + id);
      let mTargetFormReply = document.getElementById("showFormCancelReply-" + id);
      let mTargetOrderInfo = document.getElementById("order-info-container-" + id); //states and price header
      let formLoadingStatus = document.getElementById("form-LoadingStatus-" + id);

      //Loading Status
      formLoadingStatus.style.display = "flex";

      //This will include a a async Operation
      let r = await axios.post(`/api/Business/CancelShopPurchaseOrder`, null, {
        params: {
          shopId: this.$store.state.session.shopId,
          customerId: n.customerId,
          shopPurchaseOrderId: n.id,
          reason: n.reason
        },
      });
      lib.write("[Order canceled]");
      lib.write(r);
      if (r?.data?.success) {
        n.state = 4;
        n.canceledNow = true;
      }

      //Do crazy things to the inferface
      mTargetBtn.classList.add("disabled"); //disable the cancel order UI
      mTargetOrderInfo.classList.add("opacity-50"); //disable the cancel order UI
      mTargetForm.style.display = "none"; //hide the form
      mTargetFormReply.style.display = "block"; //hide the form
      formLoadingStatus.style.display = "none";
    },

    scrollTo(id) {

      const targetElement = document.getElementById(id);
      targetElement.scrollIntoView({
        behavior: 'smooth', // Scroll behavior ('auto', 'smooth')
        block: 'center',     // Vertical alignment ('start', 'center', 'end', 'nearest')
        inline: 'center'   // Horizontal alignment ('start', 'center', 'end', 'nearest')
      });
      //alert(id)
      //location.href = id;
      lib.write("scrollTo " + id)
    },

    updateSelectedYear(year) {
      this.filteredYear = year;
      lib.write("year to filter: " + year)
    },
    showAllOrdered() {
      lib.write("showAllOrdered()")
      this.filteredYear = null;
      this.filteredName = "";
      this.getPreviousOrders();
      this.audit("Listagem de Todas as Encomendas");
    },
    getProductImageUrl(p) {
      if (p) {

        //console.log("getProductImageUrl " + this.buildPath(p.photoMainPath))
        return "background-image:url(" + this.buildPath(p.photoMainPath) + ")";
      } else console.log("image not found");
    },

    passwordChangeControl() {
      //console.log(this.password_step_01)

      if (this.password_step_01.length === 0) {
        this.password_match = false;
        this.passwordStrengthScore = 0
        //console.log("return")
        return
      }

      if (this.password_step_01.length < 8) {
        this.password_match = false;
        this.passwordStrengthScore = 0
        //console.log("lenght")
        return
      }

      this.errorMessagePassword = '';
      this.checkPasswordStrength()

      if (this.password_step_02 === this.password_step_01) {
        //console.log("pw match")
        this.password_match = true;

      } else { //renders interface if new password match
        //console.log("pw not matching")
        this.password_match = false; //renders interface if new password match
      }
    },

    async updateUserAccountPassword() {

      this.isUpdatingPassword = true //shows loader - Waiting 
      this.errorMessagePassword = "" // clears previous errors

      // AXIOS updateUserAccountPassword
      let apiMethodName = "UpdateUserAccountPassword"
      let errorMsg = "";
      try {
        let r = await axios.put(
          `/api/Business/UpdateUserAccountPassword`, null, {
          params: {
            username: this.$store.state.user.username,
            oldPassword: this.oldpassword,
            newPassword: this.password_step_01
          }
        })

        if (r?.data?.success) {
          this.updatePasswordInterface('', 'passwordChanged')
          this.isUpdatingPassword = false //hides  loader - Waiting 
          this.audit("Password alterada com sucesso!")
        }
        else {
          this.updatePasswordInterface('', 'errorOnChangePW')
          this.errorMessagePassword = lib.txt('pwChange_errorOnChange') //r?.data?.message ?? 
          this.isUpdatingPassword = false //hides  loader - Waiting 
          this.errorMessage = this.errorMessagePassword
          this.audit("Ocorreu um problema na alteração da password!", r?.data?.message ?? "")
        }
      } catch (error) {
        if (error.response) {
          errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
          lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
          this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
          lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
          this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
          lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
          this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
      }
    },

    updatePasswordInterface(param, errorMessageCode) {

      // issue changing Password
      if (errorMessageCode === "errorOnChangePW") {
        this.wasPasswordUpdated = false
        this.isUpdatingPassword = false //hides loader - Waiting
        this.oldpassword = '' //clears the pw
        this.password_step_01 = '' //clears the pw
        this.password_step_02 = '' //clears the pw
        this.password_match = false //clears the pw
      }

      // issue changing Password
      if (errorMessageCode === "passwordChanged") {
        this.wasPasswordUpdated = true
      }

    },

    calculatePasswordStrengthScore() {
      const { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = this.passwordStrength;
      const regexUpperCase = /[A-Z]/;
      const regexLowerCase = /[a-z]/;
      const regexNumber = /[0-9]/;
      const regexSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;

      let score = 0;

      // Check length
      if (this.password_step_01.length >= minLength) { score += 20; }

      // Check uppercase
      if (hasUpperCase && regexUpperCase.test(this.password_step_01)) { score += 20; }

      // Check lowercase
      if (hasLowerCase && regexLowerCase.test(this.password_step_01)) { score += 20; }

      // Check numbers
      if (hasNumber && regexNumber.test(this.password_step_01)) { score += 20; }

      // Check special characters
      if (hasSpecialChar && regexSpecialChar.test(this.password_step_01)) { score += 20; }

      this.passwordStrengthScore = score;
    },

    checkPasswordStrength() {
      this.calculatePasswordStrengthScore();
    },


    async removeFromFavorites(f) {
      lib.write("\n\n[remover produto dos favoritos]");
      lib.write(f);

      this.isLoading = true

      if (this.$store.state.user.favorites) {
        this.cartPopUp = true;
        let r = await axios.post(
          `/api/Business/RemoveCustomerFavoritesByShopProductId`,
          null,
          {
            params: {
              customerId: this.$store.state.user.customerId,
              shopProductId: f.id,
            },
          }
        );
        if (r?.data?.success) {
          this.$store.commit("removeFromFavorites", f.id);
          this.audit("Produto removido dos favoritos:", f.id);
        }
        else {
          let headerError = "Error removing product from favorite for customerId: " + this.$store.state.user.customerId + " and shopProductId: " + f.id + " Detail: ";
          this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
        }

        this.isLoading = false

        setTimeout(() => {
          this.cartPopUp = false;
        }, 1500);
      }
    },
    showSection(target) {
      this.audit("Acedeu a:", target);
      this.viewForm = false;
      this.viewFavorites = false;
      this.viewOrders = false;
      this.viewOrdersOptions = false;
      this[target] = true;

    },
    logout() {
      this.audit("Cliente fez logout", '-');
      this.$store.commit("reset");
      lib.write("\n\n[Logout]");
      router.push({ name: "Login" });
    },
    goToSubCategories() {
      this.audit("Acedeu: SubCategories");
      router.push({ name: "SubCategories" });
    },
    goToProducts() {
      this.audit("Acedeu: Products");
      router.push({ name: "Products" });
    },
    goToHome() {
      this.audit("Acedeu: Home");
      router.push({ name: "Home" });
    },
    goToCart() {
      this.audit("Acedeu: Cart");
      router.push({ name: "Cart" });
    },
  },
  mounted() {
    if (!this.$store.state.isAuthenticated) router.replace({ name: "Login" });
    else {
      this.filteredYear = new Date().getFullYear();
      this.filteredName = "";
    }
  },

  computed: {
    passwordStrengthMessage() {
      return `${this.passwordStrengthScore}`;
    },
  },
};
</script>
