<template>

    <div>

        <transition name="custom-classes" enter-active-class="animate__animated animate__fadeInDown animate__faster" leave-active-class="animate__animated animate__fadeOutUp animate__faster">
        
          <div class="error-message-wrapper" v-if="showError">
            <div class="error-message-container">
        
              <div class="error-message-user">
                <i class="fa fa-message fa-flip-horizontal font-note"></i>
                {{ errorMessage }}
              </div>  
        
              <button class="error-message-close" @click="closeError('errorMessage')">
                <i class="fa fa-times-circle"></i>
              </button>
        
            </div>
        
          </div>
        </transition>


        <transition name="custom-classes" enter-active-class="animate__animated animate__fadeInDown animate__faster" leave-active-class="animate__animated animate__fadeOutUp animate__faster">
        
          <div class="error-log-wrapper" v-if="showErrorLog">
            <div class="error-message-container error-message-container-blue">         
              <div class="error-message-content"> {{ errorLog }} </div>        
              <button class="error-message-close" @click="closeError('errorLog')">
                <i class="fa fa-times-circle"></i>
              </button>
        
            </div>
        
          </div>
        </transition>

 

    </div>

</template>

<script>
import store from "../common/store";

export default {
  props: ['errorMessage', 'errorLog', 'errorBox'],
  data() {
    return {
      showError: false,
      showErrorLog: false,
      isDebug: store.state.isDebug
    };
  },
  methods: {
    closeError(param) {

      if ( param === "errorMessage" ) {
        this.showError = false;
      }

      if (param === "errorLog" ) {
        this.showErrorLog = false;
      }


    },
  },
  watch: {

    errorMessage: function () {
      console.log("changed errorMessage")   
        this.showError = true;
    },

    errorLog: function () {
      console.log("changed errorMessage")   

      if (this.isDebug) {
        this.showErrorLog = true;
      }

      
    },


    // date: function () {
    //   console.log("changed date")   
    //     this.showError = true;
    // },


    // errorLog: function () {
    //   console.log("changed errorLog")
    //   if (this.isDebug) {             
    //     this.showError = true;       
    //   }
    //   if (!this.isDebug) { 
    //     this.showError = false;
    //   } 
    // }

  },
  
};
</script>

<style>
.error-popup {
  background-color: #ffcccc;
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1999;
}
</style>
