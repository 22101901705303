<template>
    <div id="products">
        <!-- Loading Status -->
        <transition name="custom-classes" enter-active-class="animate__animated animate__fadeInDown animate__faster"
            leave-active-class="animate__animated animate__fadeOutUp animate__faster">
            <!-- Loading Bar -->
            <div class="loading-bar-01-wrapp" v-if="isLoading">
                <div class="loading-bar-01">
                    <div class="loading-bar-anim-01"></div>
                    <div class="loading-bar-anim-02"></div>
                    <div class="loading-bar-anim-03"></div>
                    <div class="loading-bar-anim-04"></div>
                </div>
            </div>
        </transition>


        <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />

     

        <!-- Message Cart PopUp-->
        <transition name="custom-classes" enter-active-class="animate__animated animate__backInUp animate__faster"
            leave-active-class="animate__animated animate__backOutDown animate__faster">

            <!-- cartPopUp -->
            <div v-if="cartPopUp" class="cart-message-wrapper">
                <div class="cart-message-container">
                    <div>
                        <!-- Loading Animation -->
                        <div class="loading-bar-02">
                            <div class="loading-bar-container-02">
                                <div class="s-w-02-elem01">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                                <div class="s-w-02-elem02">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                                <div class="s-w-02-elem03">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                                <div class="s-w-02-elem04">
                                    <i class="fa fa-circle-notch fa-spin"></i>
                                </div>
                            </div>
                        </div>
                        <img src="products/editable/cart-svgrepo-com-a.svg" alt="" />
                        <!-- A adicionar item ao Carrinho!! -->
                        {{lib.txt("info_addingTotheCart")}}
                    </div>
                </div>
            </div>
        </transition>

        <!-- Message Fav PopUp-->
        <transition name="custom-classes" enter-active-class="animate__animated animate__fadeInDown animate__faster"
            leave-active-class="animate__animated animate__fadeOutUp animate__faster">
            <div v-if="cartFavUp" class="cart-message-wrapper">
                <div class="cart-message-container">
                    <div>
                        <!-- Loading Animation -->
                        <div class="loading-bar-03">
                            <div class="loading-bar-container-02">
                                <div class="s-w-02-elem01">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                                <div class="s-w-02-elem02">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                                <div class="s-w-02-elem03">
                                    <i class="fa fa-arrow-down"></i>
                                </div>
                            </div>
                        </div>
                        <img src="products/editable/magnifying-glass-svgrepo-com-w.svg" alt="" />
                        {{ lib.txt("addingToFavorites") }}
                    </div>
                </div>
            </div>
        </transition>

        <!-- Structure Start  -->

        <div class="container-fluid bck-app">
            <div class="row">
                <!-- Col Start -->
                <div class="col-7 col-md-2 order-0 order-md-0">
                    <!-- Logo -->
                    <div id="branding" class="branding branding-light sticky-top" @click="goToHome"></div>
                </div>

                <!-- Col Start -->
                <div class="col-12 col-md-8 order-2 order-md-1">
                    <!-- Item List Repeater -->
                    <div class="prod-list-container-wrapp mt-5" v-if="!isLoading">
                        <div class="mt-3 mt-lg-5 prod-list-container-wrapp" v-if="!isLoading">

                            <!-- Banner Display - from Categories LIst -->


                            <div>
                                <div v-for="(c, index) in  $store.state.productCategories" :key="index">
                                    <div v-if="c.id === $store.state.selectedProductCategoryId">


                                        <!-- Desktop Photo -->
                                        <img v-if="c.photoBannerPhotoPath" class="item-shadow-filter-inv d-none d-lg-block"
                                            style="border-radius: 20px; margin-bottom: 20px; width: 100%;"
                                            :src="buildPath(c.photoBannerPhotoPath)">

                                        <!-- Mobile Photo -->
                                        <img v-if="c.photoBannerMobilePhotoPath" class="item-shadow-filter-inv d-lg-none"
                                            style="border-radius: 20px; margin-bottom: 20px;"
                                            :src="buildPath(c.photoBannerMobilePhotoPath)">

                                        <!-- Show Desktop Photo on Mobile -->
                                        <img v-if="!c.photoBannerMobilePhotoPath && c.photoBannerPhotoPath"
                                            class="item-shadow-filter-inv d-lg-none"
                                            style="border-radius: 20px; margin-bottom: 20px;"
                                            :src="buildPath(c.photoBannerPhotoPath)">

                                         

                                    </div>
                                </div>
                            </div>

                            <!---->
                            <div v-if="$store.state.isBetaFeature">
                                <div
                                class="badge-info mb-3 p-2 rounded"
                                v-if="viewProductById">
                                    You are Viewing a Single Page Product {{ viewProductById }}
                                </div>
                            </div>


                            <template v-for="p in $store.state.products" :key="p.id">                               
                               
                                <div v-if="!viewProductById || (viewProductById && p.id == viewProductById)">

                                    <!-- ## PopUp COLOR Modal ##-->
                                    <div v-bind:id="'color-Modal-' + p.id" class="popUp-wrapper" style="display:none">
                                        <div class="popUp-container">
                                            <!-- Close Modal BOX-->
                                            <div class="popUp-close" @click="isColorModal(p.id, 'close')">
                                                <i class="fa fa-times"></i>
                                            </div>

                                            <!-- Content Options -->
                                            <div class="popUP-frame">
                                                <h5>{{ lib.txt("availableColors") }}</h5>
                                                <hr />
                                                <!-- Colors Options-->
                                                <div class="form-options-format" v-if="p.material.availableColors?.length > 0">
                                                    <!-- List of options -->

                                                    <div class="form-check" v-for="c in p.material.availableColors" :key="c.id">
                                                        <div class="form-check-label item-shadow" @click="colorChanged(c, p)">
                                                            <!-- Color Badge-->
                                                            <i v-if="c.rgb != null" class="fa fa-circle item-shadow-filter-inv"
                                                                :style="{ color: 'rgb(' + c.rgb + ')' }"></i>
                                                            <i v-else class="fa fa-circle item-shadow-filter-inv"
                                                                :style="{ color: 'rgb(255,255,255)' }"></i>
                                                            <span>{{ $store.state.colors.find(color => color.id === c.id).name
                                                            }}</span>                                                        
                                                            <span><!-- {{ c.code }} --></span>
                                                        </div>
                                                        <div class="infobox-color-attach" v-if="!!c.obs">
                                                            <div><i class="fa-solid fa-brush color-baby-blue px-3"></i></div>
                                                            <div>{{ c.obs }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- ## Personalization Color Modal ## -->

                                    <div v-bind:id="'customize-Modal-' + p.id" class="popUp-wrapper" style="display:none">
                                        <div class="popUp-container">
                                            <!-- Close Modal BOX-->
                                            <div class="popUp-close" @click="isCustomModal(p.id, 'close')">
                                                <i class="fa fa-times"></i>
                                            </div>

                                            <!-- Content Options -->
                                            <div class="popUP-frame">
                                                <!-- Personalização Disponível -->
                                                <h5> {{lib.txt("custom_AvailablePersonalizations")}} {{ p.id }}</h5>
                                                <hr />
                                                <!-- Customization Options-->

                                                <div class="form-options-format">
                                                    <div class="form-check">
                                                        <div class="form-check-label item-shadow"
                                                            @click="customChanged(null, p)">
                                                            <span>{{ lib.txt("noCustomSelected") }}</span>
                                                        </div>
                                                    </div>


                                                    <div class="form-check" v-for="(e) in p.availableExtras ?? []" :key="e.id">
                                                        <div class="form-check-label item-shadow" @click="customChanged(e, p)">
                                                            <span>{{ e.name }}</span>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="prod-list-container">
                                        <!-- Product Photo -->
                                        <div class="prod-list-card prod-photo-bck"
                                            :style="{ backgroundImage: `url(${buildPath(p.photoMainPath)})` }">
                                            <!-- <img :src="buildPath(p.photoMainPath)" alt="" class="prod-list-card-image" /> -->
                                            <!-- Prod Action-->
                                            <div class="prod-list-action">
                                                <div @click="addToFavorites(p)">
                                                    <i class="fa fa-heart color-baby-blue"></i>
                                                </div>
                                            </div>

                                           
                                            <button 
                                            v-if="$store.state.isBetaFeature"
                                            @click="goToProductDetail(p.id)"
                                            class="bck-Yellow bottom-0 button-select end-0 m-3 position-absolute start-0 text-center">
                                            View Product Details {{ p.id }}
                                            </button>

                                        </div>                                

                                        <!-- Product Add to Cart options-->
                                        <div class="prod-list-details">
                                            <!-- Product Title-->
                                            <div class="prod-list-info sticky-top-mobile">
                                                <div class="d-flex align-items-baseline">
                                                    <div class="prod-list-Title">{{ p.name }}</div>
                                                    <div class="ml-2 prod-list-ref"> {{ p.description }}</div>
                                                </div>
                                                <div>
                                                    <span class="badge border border-bottom fw-light font-small">
                                                        max. {{ p.measure?.capacity }}ml
                                                    </span>
                                                    <span class="badge border border-bottom fw-light ms-2 font-small">
                                                        Ø{{ p.measure?.width ?? 0 }}x{{ p.measure?.height ?? 0 }}
                                                    </span>
                                                </div>

                                            </div>

                                            <!-- Item Details and Selection -->
                                            <table v-bind:id="'prodDetails-' + p.id" class="table table-custom"
                                                style="display:block">
                                                <thead>
                                                    <tr class="border-radius-control-top">
                                                        <th scope="col">{{ lib.txt("tablePriceUnitType") }}</th>
                                                        <th scope="col">{{ lib.txt("units") }}</th>
                                                        <th v-for="m in p.availableCharacteristics?.availableMaterials"
                                                            :key="m.id" v-html="getTablePriceColumnName(m, p)" scope="col"></th>
                                                        <th v-for="e in p.availableExtras ?? []" :key="e.id" v-html="e.name"
                                                            scope="col"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="t in getPriceTable(p.tablePrices.tablePrices).table" :key="t">
                                                        <td scope="row">{{ t.UnitLoad }}</td>
                                                        <td>{{ getFormatedNumber(t.Quantity) }}</td>
                                                        <td>{{ getFormatedNumber(t.MaterialA) }} €</td>
                                                        <td v-if="p.availableCharacteristics?.availableMaterials.length > 1"> {{
                                                            getFormatedNumber(t.MaterialB) }} € </td>
                                                        <td v-if="p.availableCharacteristics?.availableMaterials.length > 2"> {{
                                                            getFormatedNumber(t.MaterialC) }} € </td>
                                                        <td v-if="p.availableExtras.length > 0"> {{
                                                            getFormatedNumber(t.ProductExtraA) }} € </td>
                                                        <td v-if="p.availableExtras.length > 1"> {{
                                                            getFormatedNumber(t.ProductExtraB) }} € </td>
                                                        <td v-if="p.availableExtras.length > 2"> {{
                                                            getFormatedNumber(t.ProductExtraC) }} € </td>
                                                    </tr>

                                                    <tr class="table-custom-footer border-radius-control-bottom">

                                                        <td scope="row" colspan="2" class="py-2">
                                                            <span
                                                                class="button-information d-flex align-items-center justify-content-between ">
                                                                <div class="text-center w-100 font-title text-secondary ">
                                                                    {{ lib.txt("chooseMaterial") }}
                                                                </div>
                                                                <i class="fa fa-chevron-right text-secondary"></i>
                                                            </span>
                                                        </td>

                                                        <td v-for="(m, index) in p.availableCharacteristics?.availableMaterials"
                                                            :key="m.id">
                                                            <div class="button-select "
                                                                :class="index % 2 == 0 ? 'bck-babyBlue' : 'bck-Yellow'"
                                                                @click="materialChanged(p, m, index)">
                                                                <div class="label">
                                                                    <div>{{ getMaterialTranslated(m.id) }}</div>
                                                                    <i class="fa fa-chevron-down"></i>
                                                                </div>
                                                                <div class="button-shade"></div>
                                                            </div>
                                                        </td>

                                                        <!-- utility: render full row when availableExtras-->
                                                        <td v-for="x in p.availableExtras" :key="x"></td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!-- ShopCart Option Selection -->

                                            <table v-bind:id="'prodSelection-' + p.id" class="table table-custom"
                                                style="display:none">
                                                <tbody>
                                                    <tr
                                                        class="table-custom-footer table-custom-border border-radius-control-top-td">
                                                        <td>
                                                            <div class="button-select bg-white" @click="productPanelShow(p.id)">
                                                                <div class="label justify-content-between text-center">
                                                                    <i class="fa fa-chevron-left text-black"></i>
                                                                    <div class="w-100">
                                                                        <!-- Materials -->
                                                                        {{lib.txt("Materials")}}
                                                                    </div>
                                                                </div>
                                                                <div class="button-shade"></div>
                                                            </div>
                                                        </td>
                                                        <td colspan="2" v-bind:id="'material-items-' + p.id">
                                                            <div v-bind:id="'material-selected-' + p.id" class="button-select">
                                                                <div class="label">
                                                                    <div>--empty--</div>
                                                                </div>
                                                                <div class="button-shade"></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="m-3 p-2">

                                                        <td colspan="100%">



                                                            <!-- Prod Options -->
                                                            <div class="w-100">
                                                                <div class="row m-0 p-0">
                                                                    <!-- Quantity-->
                                                                    <div class="col-12 col-md-12 col-lg-4 table-custom-wrapp">
                                                                        <div class="table-custom-titles">
                                                                            <div>{{ lib.txt("quantity") }}</div>
                                                                            <div class="fw-light font-note"> 
                                                                                <!-- MORE QUANTITY -LOWER PRICE  -->
                                                                                {{ lib.txt("quantity_lowerPrice") }}
                                                                            </div>
                                                                        </div>

                                                                        <div class="table-custom-content">

                                                                            <div
                                                                                class="item-badge item-shadow item-dark form-Qt-Qty-padding">
                                                                                <div class="form-quantity-labels">

                                                                                    <div class="form-Qt-container">
                                                                                        <div class="form-Qt-label">
                                                                                            {{ lib.txt("units") }}
                                                                                        </div>
                                                                                        <div class="form-Qt-output"
                                                                                            v-bind:id="'qt-units-' + p.id"> {{
                                                                                                getFormatedNumber(p.quantity) }}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="form-Qt-container">
                                                                                        <div class="form-Qt-label"
                                                                                            v-html="lib.txt('dose')"></div>
                                                                                        <div class="form-Qt-output form-Qt-outputWide"
                                                                                            v-bind:id="'qt-palets-' + p.id">

                                                                                            <div> {{ p.dose }}</div>

                                                                                            <div class="form-Qt-dropdown">

                                                                                                <i class="fa fa-chevron-circle-down"
                                                                                                    @click="isPalletDropdown(p.id, 'open')">
                                                                                                </i>

                                                                                                <!-- Dropdown construct -->

                                                                                                <div v-bind:id="'qt-paletsUnit-' + p.id"
                                                                                                    class="form-Qt-dropdown-box animate__animated animate__fadeInLeft animate__faster"
                                                                                                    @click="isPalletDropdown(p.id, 'close')"
                                                                                                    style="display:none">
                                                                                                    <div class="form-Qt-dropdownItems"
                                                                                                        v-for="t in getPriceTable(p.tablePrices.tablePrices).table"
                                                                                                        :key="t">
                                                                                                        <div
                                                                                                            @click="palletChanged(p, t.UnitLoad)">
                                                                                                            {{ t.UnitLoad }}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <!-- Color -->
                                                                    <div class="col-12 col-md-12 col-lg table-custom-wrapp">
                                                                        <div class="table-custom-titles">
                                                                            <div>{{ lib.txt('color') }}</div>
                                                                        </div>

                                                                        <!-- Color modal window -->
                                                                        <div class="table-custom-content">
                                                                            <div class="item-badge item-shadow item-light item-align item-click py-3"
                                                                                @click="isColorModal(p.id, 'open')">
                                                                                <!-- Color Circle-->
                                                                                <i v-if="p.material?.availableColors?.length > 0"
                                                                                    class="fa fa-circle me-2 item-shadow-filter"
                                                                                    v-bind:id="'color-rgb-' + p.id"
                                                                                    :style="{ color: 'rgb(' + p.color.rgb + ')', }">
                                                                                </i>
                                                                                <!-- Color Label-->
                                                                                <div v-bind:id="'color-label-' + p.id"
                                                                                    v-if="p.material?.availableColors?.length > 0">
                                                                                    {{ $store.state.colors.find(x => x.id ===
                                                                                        p.color.id).name }} 
                                                                                </div>                                                                                                                                                                                                                        
                                                                                <i class="fa fa-chevron-down"></i>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Info for Color-->
                                                                        <div v-bind:id="'infobox-color-' + p.id"
                                                                            class="infobox-color" style="display:none">
                                                                            <div>
                                                                                <i
                                                                                    class="fa-solid fa-brush color-baby-blue px-3"></i>
                                                                            </div>
                                                                            <div>
                                                                                <!-- Color applicable to base only -->
                                                                                {{lib.txt("baseColorText")}}
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    <!-- Personalized -->
                                                                    <div class="col-12 col-md-12 col-lg-3 table-custom-wrapp"
                                                                        v-if="p.availableExtras.length > 0">



                                                                        <div class="table-custom-titles">
                                                                            <div>
                                                                                <!-- Personalização -->
                                                                                {{ lib.txt('custom_Personalization') }}
                                                                            </div>
                                                                        </div>

                                                                        <!-- Personalized Dropdown  -->
                                                                        <div class="table-custom-content">
                                                                            <div class="item-badge item-shadow item-light item-align item-click py-3"
                                                                                @click="isCustomModal(p.id, 'open')">

                                                                                <!-- Icon Circle-->
                                                                                <i class="fa fa-pen"></i>

                                                                                <!-- Custom Item Label-->
                                                                                <div v-bind:id="'custom-name-' + p.id"> {{
                                                                                    p.availableExtras?.length > 0 && p.extra ?
                                                                                    p.extra.name : lib.txt("noCustomSelected")
                                                                                }} </div>
                                                                                <i class="fa fa-chevron-down"></i>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Price -->
                                                                    <div class="col-12 col-md-12 col-lg-3 table-custom-wrapp">
                                                                        <div class="table-custom-titles">
                                                                            <div>{{ lib.txt('pricePer1000') }}</div>
                                                                        </div>
                                                                        <!-- Price Tag-->
                                                                        <div class="table-custom-content">
                                                                            <span
                                                                                class="item-badge item-shadow item-number item-light bg-light py-3">
                                                                                <span v-bind:id="'price-tag-' + p.id"
                                                                                    class="prod-price"> {{ p.pricePerThousand }}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <!--<span class="text-white">
                                                                    {{p.color.associatedShopProductId}} ## {{isAlreadyInCart(p.color.associatedShopProductId)}} ##
                                                                    </span>-->
                                                                    <!-- Add To Cart -->
                                                                    <div
                                                                        class="col-md-12 table-custom-footer position-relative mt-5">
                                                                        <div class="d-flex-centered">

                                                                            <div v-if="p?.color?.associatedShopProductId"
                                                                                class="button-add-cart item-shadow item-click"
                                                                                :class="isAlreadyInCart(p.color.associatedShopProductId) ? 'add-cart-disabled' : ''"
                                                                                v-bind:id="'add-cartBtn-' + p.id"
                                                                                @click="addToCart(p)">
                                                                                <img
                                                                                    src="products/editable/cart-svgrepo-com-b-add.svg" />
                                                                            </div>

                                                                            <!-- Already in Cart-->
                                                                            <transition name="custom-classes"
                                                                                enter-active-class="animate__animated animate__slideInUp animate__faster"
                                                                                leave-active-class="animate__animated animate__fadeOutUp animate__faster">
                                                                                <div class="already-in-cart"
                                                                                    v-if="isAlreadyInCart(p.color.associatedShopProductId)">
                                                                                    <div>{{ lib.txt('alreadyInCart') }}</div>
                                                                                </div>
                                                                            </transition>

                                                                            <div class="ui-curveSubtract"></div>
                                                                        </div>
                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                              </div>

                            </template>
                        </div>
                    </div>
                </div>

                <!-- Col Start // Login // Cart  -->
                <div class="col-12 col-md-2 order-1 order-md-3">
                    <!-- User Area Icon-->
                    <div class="mt-0 mt-lg-5 me-lg-5 me-0 sticky-top">
                        <!-- Top Tools / User Area / Cart View -->
                        <div class="header-options-wrapp header-options-wrapp-mobile sticky-top ">
                            <!-- User Area Icon-->
                            <div class="text-end">
                                <div id="iconUserArea" @click="goToUserArea" class="icon-user-area icon-user-dark">
                                    <i class="fa fa-user"></i>
                                    <div class="icon-animate"></div>
                                </div>
                            </div>

                            <div class="footer-cart" @click="goToCart">
                                <div class="bg-white rounded-circle">
                                    <div class="cart-item-count">
                                        {{ $store.state.user.shoppingCart?.cartItems?.length }}
                                    </div>
                                    <img src="products/editable/cart-svgrepo-com-b.svg" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- sub Category List -->
                    <div class="subcat-vertical-list sticky-top">
                        <div v-for="c in $store.state.productCategories" :key="c.id"
                            :class="c.id == $store.state.selectedProductCategoryId ? 'active' : ''"
                            @click="productCategoryChanged(c.id)">
                            {{ c.name }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import ErrorPopup from './PopUpMsg';
import axios from "axios";
import router from "../router";
import lib from "../common/library";
import global from "../common/global";
import texts from "../common/translation"
import Footer from './Footer';

export default {
    name: "Products",
    components: { ErrorPopup, Footer },
    data() {
        return {
            lib, //to be accessed inside the template
            audit: global.audit,
            productPanelShow: global.productPanelShow,
            isColorModal: global.isColorModal,  //Popup Color
            isCustomModal: global.isCustomModal, //Popup Personalization
            isPalletDropdown: global.isPalletDropdown,
            buildPath: global.buildPath,
            getPriceTable: global.getPriceTable,
            getFormatedNumber: lib.getFormatedNumber,
            getColorTranslated: global.getColorTranslated,
            getMaterialTranslated: global.getMaterialTranslated,
            texts,
            cartPopUp: false,
            cartFavUp: false,
            isPopUp: false, //modal Window
            isLoading: false, //Loader Status

            //FORM Boxes
            option_colors: false,

            errorMessage: '', //errorBox
            errorLog: '', //errorBox
            errorBox: false, //errorBox

            viewProductById: ''
        };
    },
    methods: {
        getDefaultColorIdByMaterialId(p, id) {
            console.log("getDefaultColorIdByMaterialId")
            let colorRow = this.getPriceTable(p.tablePrices.tablePrices).colorRow;
            for (const materialKey in colorRow) {
                if (materialKey == 'MaterialA' || materialKey == 'MaterialB' || materialKey == 'MaterialC' || materialKey == 'MaterialD' || materialKey == 'MaterialE') {
                    // Get the integer part of the material value
                    let intValue = parseInt(colorRow[materialKey]);
                    // Check if the integer part matches the provided materialId
                    if (intValue === id) {
                        //7.6

                        // Convert the number to a string
                        let numberStr = colorRow[materialKey].toString();
                        // Split the string at the decimal point
                        let parts = numberStr.split('.');
                        let colorId = parseInt(parts[1][0]);
                
                        // Get the decimal part of the material value as an integer
                        // let colorId = parseInt((colorRow[materialKey] % 1) * 10);
                        // let colorId = parseInt((colorRow[materialKey] % 1 * 10).toFixed(0));
                        return colorId;
                    }
                }
            }

            return null;
        },
        getTablePriceColumnName(m, p) {
            console.log("getTablePriceColumnName")
           
            let colorRow = this.getPriceTable(p.tablePrices.tablePrices).colorRow;
            let colorId = null;
            let color = null;
            let columnText = lib.txt('columnColorlessPrice');

            for (const materialKey in colorRow) {
                if (materialKey == 'MaterialA' || materialKey == 'MaterialB' || materialKey == 'MaterialC' || materialKey == 'MaterialD' || materialKey == 'MaterialE') {
                    // Get the integer part of the material value
                    let intValue = parseInt(colorRow[materialKey]);
                    // Check if the integer part matches the provided materialId
                    if (intValue === m.id) {
                        // Get the decimal part of the material value as an integer
                       
                        colorId = this.getDecimalPartAsInt(colorRow[materialKey])                       
                        
                        if (colorId > 1) {
                            color = this.$store.state.colors.find(color => color.id === colorId);
                            // color = m.availableColors.find(color => color.id === colorId);
                            if (color)
                                columnText = lib.txt("columnColorPrice").replace("[COLOR]", color.name);
                        }
                    }
                }
            }




            // let r = await axios.get(`/api/Utils/GetProductColor`, {
            //     params: {
            //         productColorId: colorId,
            //         languageId: this.$store.state.selectedLanguageId
            //     },
            // });
            // if (r?.data?.success) {
            //     color = r.data.responseData;
            //     console.log("ha cor:")
            //     console.log(color);
            //     columnText = color ? color.name + '  </br>  €/1000' : lib.txt('columnColorlessPrice');
            // }           

            return columnText;
        },

        
        async palletChanged(p, dose) {
            global.palletChanged(p, dose, false);
            p.pricePerThousand = await this.getProductPrice(p);
        },
        async colorChanged(c, p) {
            let r = await global.colorChanged(c, p, false);
           // global.colorChanged(c, p, false);
            p.pricePerThousand = await this.getProductPrice(p);
        },
        async customChanged(e, p) {
            global.customChanged(e, p, false);
            p.pricePerThousand = await this.getProductPrice(p);
        },

        async getProductPrice(p) {
            lib.write("shopProductId: " + p.color.associatedShopProductId + "\n quantity: " + p.quantity + "\n productMaterialId: " + p.material.id + "\n productColorId: " + p.color.id, "Preço do produto")
            let r = await axios.get(`/api/Business/GetDefaultPrice`, {
                params: {
                    userId: this.$store.state.user.id,
                    shopProductId: p.color.associatedShopProductId,
                    quantity: p.quantity,
                    productExtraId: p.extra?.id
                },
            });
            if (r?.data?.success) {
                return r.data.responseData
            }

            return "-"; // Error
        },

        isAlreadyInCart(id) {
            return (lib.belongs(id, this.$store.state.user.shoppingCart.cartItems, true))
        },

        getDecimalPartAsInt(num) { //4.1
            //const decimalPart = num - Math.floor(num);
            const decimalDigits = num.toString().split('.')[1];
            return parseInt(decimalDigits);
        },

        async addToCart(p) {
            this.cartPopUp = true;
            //this.errorLog = p
            lib.write(p, "produto a adicionar ao carrinho");
            let r = await axios.post(
                `/api/Business/AddProductToShoppingCart`,
                null,
                {
                    params: {
                        userId: this.$store.state.user.id,
                        shoppingCartId: this.$store.state.user.shoppingCart.id,
                        shopProductId: p.color.associatedShopProductId,
                        quantity: p.quantity,
                        productExtraId: p.extra?.id
                    },
                }
            );
            if (r?.data?.success) {
                lib.write(r.data, "add to cart resposta");

                if (!lib.belongs(p.color.associatedShopProductId, this.$store.state.user.shoppingCart.cartItems, true)) {
                    this.$store.commit("addToCart", {
                        id: p.color.associatedShopProductId,
                        photoMainPath: p.photoMainPath,
                        quantity: p.quantity,
                        name: p.name,
                        description: p.description,
                        totalPrice: r.data.responseData.totalPrice ?? 0,
                        extrasPrice: r.data.responseData.extrasPrice ?? 0,
                        finalPrice: r.data.responseData.finalPrice ?? 0,
                        color: p.color,
                        material: p.material,
                        rule: global.getUnitsRule(p.unitLoads),
                        availableMaterials: p.availableMaterials,
                        availableColors: p.material.availableColors,
                        availableExtras: p.availableExtras,
                        extra: p.extra,
                        dose: p.dose,
                        cartProductId: r.data.responseData.id,
                        measure: p.measure,
                        tablePrices: p.tablePrices,
                        minimumUnits: p.minimumUnits,
                        minimumBoxes: p.minimumBoxes
                    });

                    this.audit("Producto adicionado ao cart", p.id);
                }
                // Se existir utiliza as quantidades calculadas em serverside
                else {
                    let quantities = { units: p.quantity, dose: p.dose }

                    if (r.data.responseData.totalPrice)
                        p.totalPrice = r.data.responseData.totalPrice;

                    if (r.data.responseData.color)
                        p.color = r.data.responseData.color

                    this.$store.commit("updateCart", { id: p.material.associatedShopProductId, quantities, color: p.color, totalPrice: p.totalPrice })

                    this.audit("Producto adicionado ao cart", p.id);
                }
            }
            else {
                let headerError = "Error adding producto to shopcart for shoppingCartId: " + this.$store.state.user.shoppingCart.id + " and shopProductId: " + p.material.associatedShopProductId + " and quantity:" + p.quantity + " Detail: ";
                this.audit("Erro ao adicionar ao cart", p.id);
            }

            setTimeout(() => {
                this.cartPopUp = false;
            }, 1500);
        },

        async materialChanged(p, m, index) {
            lib.write(m, "novo material")

            if (index == 0)
                m.key = "A"
            else if (index == 1)
                m.key = "B"
            else if (index == 2)
                m.key = "C"

            this.$store.commit("productSelectedMaterial", { id: p.id, material: m })
            let colorId = this.getDefaultColorIdByMaterialId(p, m.id)
            if (colorId != null && colorId > 1)
            {
                p.color = m.availableColors.find(x => x.id === colorId)                
                p.color.name = this.$store.state.colors.find(color => color.id === colorId).name;
            }
            else
                p.color = m.availableColors[0];
            
            //console.log(p.color);
            global.productPanelShow(p.id);
            global.materialCreate(m.id, index % 2 == 0 ? 'bck-babyBlue' : 'bck-Yellow', p.id)
            p.pricePerThousand = await this.getProductPrice(p);
            lib.write(p, "produto com material selecionado");
            this.audit("Material escolhido ", m.name + " (" + p.name + ")");
        },
        async addToFavorites(p) {

            this.errorMessage = '' //this will clear previous error Messages

            lib.write("\n\n[produto a adicionar aos favoritos]");
            lib.write(p);
            if (
                !this.$store.state.user.favorites ||
                !lib.belongs(p.id, this.$store.state.user.favorites)
            ) {
                this.cartFavUp = true;
                let r = await axios.post(`/api/Business/AddToCustomerFavorites`, null, {
                    params: {
                        customerId: this.$store.state.user.customerId,
                        shopProductId: p.id,
                    },
                });

                if (r?.data?.success) {
                    this.$store.commit("addToFavorites", {
                        id: p.id,
                        photoMainPath: p.photoMainPath,
                    });
                    this.audit("Produto adicionado aos favoritos", p.id);
                }
                else {
                    let headerError = "Error adding product to favorites for customerId: " + this.$store.state.user.customerId + " and shopProductId: " + p.id + " Detail: ";
                    this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
                    this.audit("Ocorreu um erro ao adicionar favoritos", p.id);
                }

                setTimeout(() => {
                    this.cartFavUp = false;
                }, 1500);
            }
            else {
                lib.write("Produto já existente nos favoritos!");

                this.errorMessage = lib.txt("favoriteDuplicate");
                this.errorLog = "Id: " + p.id + " " + Date.now().toLocaleString()
            }
        },

        productCategoryChanged(id) {

            this.audit("Menu Lateral: Categoria de Produtos", id);

            if (this.isLoading)
                return;

            this.$store.commit("selectedProductCategory", id);
            this.$store.commit("products", []);
            this.getProducts();
            this.goToProducts()
        },

        async getProducts() {
            this.isLoading = true; //Loader Status
            let r = await axios.get(
                `/api/Business/GetShopProductShowcasesDTOByBusinessShopAndCategory`,
                {
                    params: {
                        userId: this.$store.state.user.id,
                        shopId: this.$store.state.session.shopId,
                        subCategoryId: this.$store.state.selectedProductCategoryId,
                        languageId: this.$store.state.selectedLanguageId
                    },
                }
            );
            lib.write("\n\n[products result]");
            lib.write(r);
            if (
                r?.data?.success &&
                r.data.responseData &&
                r.data.responseData.products
            ) {
                Array.from(r.data.responseData.products).forEach((p) => {
                    p.extra = null;
                    if (p.availableCharacteristics?.availableMaterials) {
                        p.material = p.availableCharacteristics.availableMaterials[0];
                        p.material.key == "A";
                        if (p.availableCharacteristics.availableMaterials[0].availableColors?.length > 0)
                            p.color = p.availableCharacteristics.availableMaterials[0].availableColors[0];
                    }

                    p.rule = global.getUnitsRule(p.unitLoads);

                    if (!p.tablePrices || !p.tablePrices.tablePrices)
                        p.tablePrices = { tablePrices: "" }
                    let item = p.minimumUnits ? global.getPriceTableItemByQuantity(p.tablePrices.tablePrices, p.minimumUnits) : global.getPriceTable(p.tablePrices.tablePrices).table[0];
                    if (!item?.UnitLoad)
                        item = global.getPriceTable(p.tablePrices.tablePrices).table[0];

                    global.palletChanged(p, item.UnitLoad, false)
                    p.pricePerThousand = this.getProductPrice(p);
                });

                this.$store.commit("products", r.data.responseData.products);
            }
            else if (!r.data.success) {
                let headerError = "Error loading products for shopId: " + this.$store.state.session.shopId + " and subcategoryId: " + this.$store.state.selectedProductCategoryId + " Detail: ";
                this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
            }

            this.isLoading = false; //Loader Status
        },


        goToCart() {
            this.audit("Acedeu: Cart");
            router.push({ name: "Cart" });
        },
        goToProducts() {
            this.audit("Acedeu: Products");           
            router.push({ name: "Products"});
        },
        goToSubCategories() {
            this.audit("Acedeu: Subcategorias");
            router.push({ name: "SubCategories" });
        },
        goToHome() {
            this.audit("Acedeu: Pagina Inicio");
            router.push({ name: "Home" });
        },
        goToUserArea() {
            this.audit("Acedeu: Area de Utilizador");
            router.push({ name: "UserArea" });
        },
        goToProductDetail(productId) {
            this.audit("Acedeu: Detalhe de Produto");
            router.push({ name: "ProductDetail", params: { productId } });
            this.viewProductById = productId
        },
    },
    async mounted() {
        if (!this.$store.state.isAuthenticated) router.replace({ name: "Login" });
        else if (this.$store.state.selectedProductCategoryId != null) {
            await this.getProducts();
        }
        this.viewProductById = this.$route.params.productId;
    },
};
</script>
