<template>
    <div id="productDetail">

                <!-- Structure Start  -->

                    <div class="container-fluid bck-app" style="display: none;">
                        <div class="row">
                            <!-- Col Start -->
                            <div class="col-7 col-md-2 order-0 order-md-0">
                                <!-- Logo -->
                                <div id="branding" class="branding branding-light sticky-top" @click="goToHome"></div>
                            </div>

                            <!-- Col Start -->
                            <div class="col-12 col-md-8 order-2 order-md-1">
                                <!-- Item List Repeater -->
                                <div class="prod-list-container-wrapp mt-5" v-if="!isLoading">
                                    <div class="mt-3 mt-lg-5 prod-list-container-wrapp" v-if="!isLoading">

                                        <!-- Banner Display - from Categories LIst -->
        

                                    </div>
                                </div>
                            </div>

                            <!-- Col Start // Login // Cart  -->
                            <div class="col-12 col-md-2 order-1 order-md-3">
                                <!-- User Area Icon-->
                                <div class="mt-0 mt-lg-5 me-lg-5 me-0 sticky-top">
                                    <!-- Top Tools / User Area / Cart View -->
                                    <div class="header-options-wrapp header-options-wrapp-mobile sticky-top ">
                                        <!-- User Area Icon-->
                                        <div class="text-end">
                                            <div id="iconUserArea" @click="goToUserArea"
                                                class="icon-user-area icon-user-dark">
                                                <i class="fa fa-user"></i>
                                                <div class="icon-animate"></div>
                                            </div>
                                        </div>

                                        <div class="footer-cart" @click="goToCart">
                                            <div class="bg-white rounded-circle">
                                                <div class="cart-item-count">
                                                    {{ $store.state.user.shoppingCart?.cartItems?.length }}
                                                </div>
                                                <img src="products/editable/cart-svgrepo-com-b.svg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- sub Category List -->
                                <div class="subcat-vertical-list sticky-top">
                                    <div v-for="c in $store.state.productCategories" :key="c.id"
                                        :class="c.id == $store.state.selectedProductCategoryId ? 'active' : ''"
                                        @click="productCategoryChanged(c.id)">
                                        {{ c.name }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Products></Products>

        <div v-if="errorMessage || errorLog">
            <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />
        </div>


        <!-- <p>Product ID: {{ productId }}</p>
        <p>{{ productName }}</p>
        {{ p }} -->


        <Footer />
    </div>
</template>
  
<script>
import axios from "axios";
import lib from "../common/library";
import global from "../common/global";
import ErrorPopup from './PopUpMsg';
import Footer from './Footer';
import Products from './Products';

export default {
    name: "ProductDetail",
    //props: ['productId'],
    components: { ErrorPopup, Footer, Products },
    data() {
        return {
            audit: global.audit,
            productId: null,
            productName: null,
            isLoading: false,
            errorMessage: '', //errorBox
            errorLog: '', //errorBox
        };
    },
    methods: {
        async getProduct(id) {
            this.isLoading = true; //Loader Status
            let r = await axios.get(
                `/api/Business/GetShopProductById`,
                {
                    params: {
                        userId: this.$store.state.user.id,
                        shopProductId: id,
                        languageId: this.$store.state.selectedLanguageId
                    },
                }
            );
            lib.write("\n\n[product result]");
            lib.write(r);
            this.isLoading = false
            if (r?.data?.success && r.data.responseData) {
                let p = r.data.responseData                
                this.audit("Página de produto acedida:", p.name);
                return p
            }
            else {
                let headerError = "Error getting product info Detail: ";
                this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
            }
        },
    },

    async mounted() {
        this.productId = this.$route.params.productId;
        let p = await this.getProduct(this.productId)
        this.productName = p.name
    }
};
</script>