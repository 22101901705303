<template>
  <div id="categories">

      <!-- Loading Status -->
      <transition 
        name="custom-classes" enter-active-class="animate__animated animate__fadeInDown animate__faster"
        leave-active-class="animate__animated animate__fadeOutUp animate__faster">
        <!-- Loading Bar -->
        <div class="loading-bar-01-wrapp" v-if="isLoading">
          <div class="loading-bar-01">
            <div class="loading-bar-anim-01"></div>
            <div class="loading-bar-anim-02"></div>
            <div class="loading-bar-anim-03"></div>
            <div class="loading-bar-anim-04"></div>
          </div>
        </div>
      </transition>

    

    <div class="header-banner-01">
      <!-- Header Component-->
      <div class="header-container">
        <!-- Logo -->
        <div
          id="branding"
          class="branding branding-dark"
          @click="goToHome">
        </div>

        <!-- Top Tools / User Area / Cart View -->
        <div class="header-options-wrapp sticky-top">
          <!-- User Area Icon-->
          <div class="text-end">
            <div
              id="iconUserArea"
              @click="goToUserArea"
              class="icon-user-area icon-user-dark">
              <i class="fa fa-user"></i>
              <div class="icon-animate"></div>
            </div>
          </div>

          <div class="footer-cart" @click="goToCart">
            <div class="bg-white rounded-circle">
              <div class="cart-item-count">
                {{ $store.state.user.shoppingCart?.cartItems?.length }}
              </div>
              <img src="products/editable/cart-svgrepo-com-b.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="CategoryList">

    
        <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />
     

      <!-- Welcome UserName-->
      <div class="welcome-username">
        <div>{{ lib.txt("welcome") }}</div>
        <div>{{ $store.state.user.name }}</div>
      </div>

      <div class="category-wrapp">
        <!-- Card-->
          <div class="category-card"
               v-for="c in $store.state.categories" :key="c.id">

              <div @click="goToSubCategories(c.id)" :class="{ 'category-disabled': c.showcase.stateId == 1 }">
                  <img :src="buildPath(c.photoMainPath)" alt="" class="category-card-img" />
                  <div class="category-title">{{ c.name }}</div>
                  <div class="category-descr" v-html=" c.description?.replaceAll('\\n','<br />')"></div>
              </div>
              <div v-if="c.showcase.stateId == 1" class="category-soon">{{c.showcase.stateComplement}}</div>

              <!-- c.stateId == 1 -->
              <!-- c.StateDescription -->
              <!-- c.StateComplement  -->

          </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ErrorPopup from './PopUpMsg';
import router from "../router";
import lib from "../common/library"
import global from "../common/global";
import texts from "../common/translation"
import Footer from "./Footer"


export default {
  name: "Categories",
  components: { ErrorPopup, Footer },
  data() {
      return {
      audit: global.audit,
      errorMessage: '', //errorBox
      errorLog: '', //errorBox
      isLoading: false, //Loader Status
      getSubcategories: global.getSubcategories,
      buildPath: global.buildPath,
      texts,
      lib,
    };
  },
  methods: {
      goToSubCategories(categoryId) {
      this.audit("Acesso Subcategoria", categoryId);
      this.$store.commit("selectedCategory", categoryId);
      router.push({ name: "SubCategories" });
    },
    goToUserArea() {
      this.audit("Acedeu: UserArea");
      router.push({ name: "UserArea" });
    },
      goToCart() {
      this.audit("Acedeu: Cart");
      router.push({ name: "Cart" });
    },
      goToHome() {
      this.audit("Acedeu: P�gina Inicio");
      router.push({ name: "Home" });
    },
  },
  async mounted() {
    if (!this.$store.state.isAuthenticated) router.replace({ name: "Login" });
    else {
      this.isLoading = true;
    let r = await this.getSubcategories(null, "categories", null);
    if (r)
      this.errorLog = r;

    }
    this.isLoading = false;
  },
};
</script>
