<template>
    <div class="background-login w-100 h-100">

        <div class="label-version">{{ $store.state.version }}</div>

        <!-- Header-->
        <div class="logo-holder">
            <img src="products/editable/logo_plasticos_futura-01.png" class="img-fluid" />
        </div>

        <!-- Header-->
        <div class="container-fluid header-float form-z-index">
            <div class="row">
                <div class="col-12 d-flex justify-content-center justify-content-lg-end">
                    <ul class="lang-selector">
                        <li :class="this.browserLang == 1 ? 'active' : ''" @click="this.getContentForLanguage(1)"><a
                                href="#">EN</a> </li>
                        <li :class="this.browserLang == 2 ? 'active' : ''" @click="this.getContentForLanguage(2)"><a
                                href="#">PT</a></li>
                        <li :class="this.browserLang == 3 ? 'active' : ''" @click="this.getContentForLanguage(3)"><a
                                href="#">ES</a></li>
                        <li :class="this.browserLang == 4 ? 'active' : ''" @click="this.getContentForLanguage(4)"><a
                                href="#">FR</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Login Box -->
        <div class="container-fluid text-center form-z-index">
            <div class="row">
                <!-- Empty Col for padding-->
                <div class="col"></div>
                <!-- Form Structure -->
                <div class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 transition-fast ">
                    <!-- Form Title -->
                    <div class="login-title-container">
                        <div class="login-box-title">{{ lib.txt("reservedArea") }}</div>
                        <div class="login-box-subtitle">{{ lib.txt("customerlogin") }}</div>
                    </div>

                    <!-- Login Form -->
                    <div class="login-box-container p-2 p-md-5 p-lg-5">

                        <!-- Login Related -->
                        <div v-if="interfaceMode === 'login'">

                            <!-- Input-->
                            <div class="login-input-container">
                                <i class="fa fa-envelope"></i>
                                <input v-model="username" type="email" :placeholder="lib.txt('email')" 
                                name="username" id="username"  autocomplete="username"/>
                            </div>
                            <!-- Input-->
                            <div class="login-input-container">
                                <i class="fa fa-lock"></i>
                                <input v-model="password" type="password" :placeholder="lib.txt('password')" @keyup.enter="login"  
                                name="password" id="password"  autocomplete="current-password"/>
                            </div>
                            <div class="badge bg-danger w-100 p-2">{{ errorMessage }}</div>


                            <!-- Submit Button-->
                            <button @click="login" class="login-form-submit mt-5">
                                <transition name="custom-classes" enter-active-class="animate__animated animate__bounceIn"
                                    leave-active-class="animate__animated animate__fadeOutUp">
                                    <div class="spinner-wrapp" v-if="isLoading">
                                        <i class="fa fa-circle-notch fa-spin fa-custom-spinner"> </i>
                                    </div>
                                </transition>
                                <div>{{ lib.txt("login") }}</div>
                            </button>

                            <!-- Temporary -->
                            <!-- <button
                                class="border btn-group mt-3 px-2 py-1 shadow text-center button-simple-outline align-items-center bg-black"
                                @click="loginInterface('updatePassword', '101')"><i class="fa fa-bug me-2 p-0 m-0">
                                </i>Enter recovery form</button> -->
                        </div>

                        <!-- Password Related -->
                        <div v-if="interfaceMode === 'updatePassword'" class="position-relative">

                            <div class="c-white">
                                <span class="fa-stack fa-2x m-0 p-0 my-3 position-relative">

                                    <i class="fa fa-shield fa-stack-2x" style="opacity: 0.5;"></i>
                                    <i class="fa fa-key fa-stack-1x fa-inverse"></i>

                                    <!-- Check Badge-->
                                    <transition name="custom-classes"
                                        enter-active-class="animate__animated animate__bounceIn"
                                        leave-active-class="animate__animated animate__bounceOut">
                                        <div v-if="wasPasswordUpdated"
                                            class="bg-success fa fa-check p-2 position-absolute rounded-pill"
                                            style="right: -15px;top: -3px; font-size: 0.5em;"> </div>
                                    </transition>

                                </span>

                                <!-- Password Change Notice -->
                                <div v-if="!wasPasswordUpdated">
                                   {{lib.txt('pwChange_Notice')}}
                                </div>

                                <!-- Password Was updated Notice -->

                                <transition name="custom-classes"
                                    enter-active-class="animate__animated animate__fadeInUp animate__faster"
                                    leave-active-class="animate__animated animate__fadeInDown animate__faster">

                                    <div v-if="wasPasswordUpdated">

                                        <!-- A sua password foi actualizada com sucesso. Volte a fazer login. -->
                                        {{ lib.txt('pwChange_Modificado') }}

                                        <!-- Finalizar Update Password-->
                                        <div class="bck-babyBlue button-select shadow text-center mt-3"
                                            @click="loginInterface('login')">
                                            <i class="fa fa-chevron-left me-2"></i>{{ lib.txt("login") }}
                                        </div>

                                    </div>

                                </transition>

                            </div>

                            <div class="my-4" v-if="!wasPasswordUpdated">
                                <div class="row text-left c-2202 position-relative">

                                    <!-- Is loading section -->
                                    <div id="isUpdatingPassword" class="isLoading-Zone" v-if="isUpdatingPassword">
                                        <div class='loader-wrapper'>
                                            <div class='loader text-center'>
                                                <div class='spinner'></div>
                                                <p>{{ lib.txt("info_Updating") }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Form Update Password -->
                                    <div class="col-12 position-relative">
                                        <!-- Input password step 01 -->
                                        <form>



                                            <div>
                                                <div class="mb-2 d-flex justify-content-between">
                                                    <div>{{lib.txt("new_password")}}</div>
                                                    <!-- <div v-if="password_step_01.length > 0" class="badge badge-info font-small">{{ password_step_01.length }}</div> -->
                                                </div>
                                                <div class="login-input-container">
                                                    <i class="fa fa-lock"></i>
                                                    <input type="password" id="password-step-01"
                                                        v-model.trim="password_step_01" @keyup="passwordChangeControl()" />
                                                </div>
                                            </div>

                                            <!-- Input password step 02 -->
                                            <div :class="{ disabled: password_step_01 === '' }">
                                                <!-- Confirm password -->
                                                <div class="mb-2">{{lib.txt("password_confirm")}}</div>
                                                <div class="login-input-container">
                                                    <i class="fa fa-lock"></i>
                                                    <input type="password" id="password-step-02"
                                                        v-model.trim="password_step_02" @keyup="passwordChangeControl()" />
                                                </div>
                                            </div>

                                        </form>

                                        <!-- Compare Status -->
                                        <div class="password-compare-status d-flex-centered">

                                            <div v-if="!password_match" class="icon-round-x1  m-0 p-0 bg-danger"
                                                style="z-index: 1;">
                                                <i class="fa fa-times"></i>
                                            </div>

                                            <div v-if="password_match" class="icon-round-x1  m-0 p-0 bck-babyBlue"
                                                style="z-index: 1;">
                                                <i class="fa fa-check"></i>
                                            </div>

                                            <div class="half-box-centered"></div>

                                        </div>

                                    </div>

                                    <!-- Strength Chart -->
                                    <div class="row">
                                        <div class="col">
                                            <!-- Segurança -->
                                            <div class="text-white font-note font-weight-bold">{{lib.txt('pwChange_Seguranca')}} {{ passwordStrengthMessage }} %</div>
                                        </div>
                                        <div class="col">
                                            <div class="w-100 position-relative">
                                                <div class="password-strength-meter" style="background-color: #011c3636;"
                                                    :class="{ 'weak-password': passwordStrengthScore <= 40 }">
                                                    <div :style="{ width: passwordStrengthScore + '%' }"></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- Error Message - Password change-->
                                    <transition name="custom-classes"
                                        enter-active-class="animate__animated animate__fadeInUp animate__faster"
                                        leave-active-class="animate__animated animate__fadeOutDown animate__faster">

                                        <div v-if="errorMessage !== ''" class="badge bg-danger w-100 p-2 mt-2">{{
                                            errorMessage }}</div>

                                    </transition>


                                    <div class="col-12 d-flex-centered" :class="{ disabled: !password_match }"
                                        @click="updateUserAccountPassword()">
                                        <!-- Finalizar Update Password-->
                                        <div class="bck-babyBlue button-select shadow text-center mt-3">
                                            {{lib.txt('btn_Finalizar')}}
                                        </div>
                                    </div>

                                    <!-- Segurança Rules -->
                                    <div class="text-note font-01 text-center my-2 text-light">
                                        <div v-html="lib.txt('pwChange_minimum')"></div>
                                        <div class="font-small" v-html="lib.txt('pwChange_Rules')">
                                       
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
                <!-- Empty Col for padding-->
                <div class="col"></div>
            </div>
        </div>

        <!-- Footer-->
        <div class="login-text-footer">
            {{ lib.txt("loginBottomInfo") }}
        </div>
    </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import lib from "../common/library"
import global from "../common/global";
import texts from "../common/translation"
export default {
    name: "Login",
    data() {
        return {
            getQuantityByUnit: global.getQuantityByUnit,
            audit: global.audit,
            texts,
            lib,
            email: "",
            password: "",
            errorMessage: "",
            isLoading: false,
            username: "",
            browserLang: "",

            //Password Change
            interfaceMode: 'login',
            isUpdatingPassword: false, // UI logic
            wasPasswordUpdated: false, // UI logic
            password_step_01: '',
            password_step_02: '',
            password_match: false,

            //Password Strength
            passwordStrength: { minLength: 8, hasUpperCase: true, hasLowerCase: true, hasNumber: true, hasSpecialChar: true, },
            passwordStrengthScore: 0,
        };
    },




    methods: {

        async detectLanguage() {

            //debugger

            const languageMappings = {
                en: 1,
                pt: 2,
                es: 3,
                fr: 4,
            };

            //Spliting this, because this varies among browsers ex: edge is en-GB
            const userLanguage = navigator.language.toLowerCase().split("-");

            if (userLanguage[0] in languageMappings) {
                this.browserLang = languageMappings[userLanguage[0]] //writes Variable
                this.getContentForLanguage(this.$store.state.selectedLanguageId) //writes to Store.State
                this.getContentForLanguage(languageMappings[userLanguage[0]]) //switch the language 
            } else {
                // Default to English if user language is not supported
                this.browserLang = 1;
            }

        },

        passwordChangeControl() {
            //console.log(this.password_step_01)

            if (this.password_step_01.length === 0) {
                this.password_match = false;
                this.passwordStrengthScore = 0
                //console.log("return")
                return
            }

            if (this.password_step_01.length < 8) {
                this.password_match = false;
                this.passwordStrengthScore = 0
                //console.log("lenght")
                return
            }

            this.errorMessage = '';
            this.checkPasswordStrength()

            if (this.password_step_02 === this.password_step_01) {
                console.log("pw match")
                this.password_match = true;

            } else { //renders interface if new password match
                console.log("pw not matching")
                this.password_match = false; //renders interface if new password match
            }
        },

        async updateUserAccountPassword() {

            this.isUpdatingPassword = true //shows loader - Waiting 
            this.errorMessage = "" // clears previous errors


            // AXIOS updateUserAccountPassword
            let apiMethodName = "UpdateUserAccountPassword"
            let errorMsg = "";
            try {
                let r = await axios.put(
                    `/api/Business/UpdateUserAccountPassword`, null, {
                    params: {
                        username: this.username,
                        oldPassword: this.password,
                        newPassword: this.password_step_01
                    }
                })

                if (r?.data?.success) {
                    this.audit("Password alterada com sucesso!")
                    this.loginInterface('', 'passwordChanged')
                    this.isUpdatingPassword = false //hides  loader - Waiting 
                }
                else {
                    this.loginInterface('', 'errorOnChangePW')
                    this.errorMessage = r?.data?.message ?? lib.txt('pwChange_errorOnChange');
                    this.isUpdatingPassword = false //hides  loader - Waiting 
                    this.audit("Ocorreu um problema na alteração da password!", r?.data?.message ?? "")
                }
            } catch (error) {
                if (error.response) {
                    errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
                    lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
                    this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
                } else if (error.request) {
                    lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
                    this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
                } else {
                    lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
                    this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
                }
            }
        },

        loginInterface(param, errorMessageCode) {

            //alert(errorMessageCode)

            if (param === "login") {
                this.interfaceMode = 'login'

                //resets interface from older update password attempts:
                this.errorMessage = ''
                this.password_step_01 = '' //clears the pw
                this.password_step_02 = '' //clears the pw 
                this.password_match = false //clears the pw  
            }

            //should be 102
            if (errorMessageCode === "102") {
                this.errorMessage = ''
                this.interfaceMode = 'updatePassword'
                this.wasPasswordUpdated = false
                this.password_step_01 = ''
                this.password_step_02 = ''
            }

            // issue changing Password
            if (errorMessageCode === "errorOnChangePW") {
                this.wasPasswordUpdated = false
                this.isUpdatingPassword = false //hides  loader - Waiting
                this.password_step_01 = '' //clears the pw
                this.password_step_02 = '' //clears the pw 
                this.password_match = false //clears the pw          
            }

            // issue changing Password
            if (errorMessageCode === "passwordChanged") {
                this.wasPasswordUpdated = true
            }

        },

        calculatePasswordStrengthScore() {
            const { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = this.passwordStrength;
            const regexUpperCase = /[A-Z]/;
            const regexLowerCase = /[a-z]/;
            const regexNumber = /[0-9]/;
            const regexSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;

            let score = 0;

            // Check length
            if (this.password_step_01.length >= minLength) { score += 20; }

            // Check uppercase
            if (hasUpperCase && regexUpperCase.test(this.password_step_01)) { score += 20; }

            // Check lowercase
            if (hasLowerCase && regexLowerCase.test(this.password_step_01)) { score += 20; }

            // Check numbers
            if (hasNumber && regexNumber.test(this.password_step_01)) { score += 20; }

            // Check special characters
            if (hasSpecialChar && regexSpecialChar.test(this.password_step_01)) { score += 20; }

            this.passwordStrengthScore = score;
        },

        checkPasswordStrength() {
            this.calculatePasswordStrengthScore();
        },

        async login() {
            // if (this.$store.state.isDebug)
            //     this.username = this.password = "testtest"
            if (this.username && this.password) {
                this.isLoading = true; //shows de loading spinner
                this.errorMessage = '' //clears the previous error message

                let apiMethodName = "login"
                let errorMsg = "";
                try {

                    let r = await axios.post(`/api/Session/login`, null, {
                        params: {
                            username: this.username,
                            password: this.password,
                            languageId: this.$store.state.selectedLanguageId
                        },
                    });
                    lib.write("[login result]");
                    lib.write(r);
                    if (r?.data?.success) {
                        this.$store.commit("login", true);

                        if (r.data?.responseData) {

                            //Get all colors translated
                            let c = await axios.get(`/api/Utils/getProductColors`, {
                                params: {
                                    languageId: this.$store.state.selectedLanguageId
                                }
                            });
                            if (c?.data?.success)
                                this.$store.commit('colors', c.data.responseData);

                            //Get all materials translated
                            let m = await axios.get(`/api/Utils/getProductMaterials`, {
                                params: {
                                    languageId: this.$store.state.selectedLanguageId
                                }
                            });
                            if (m?.data?.success)
                                this.$store.commit('materials', m.data.responseData);


                            let d = r.data.responseData;

                            console.log (d)

                            // -= USER =-
                            this.$store.commit("user", {
                                id: d.userId,
                                customerId: d.customerId,
                                username: d.userName,
                                number: d.userName,
                                firstName: d.customerFirstName,
                                name: d.customerName,
                                surname: d.customerLastName,
                                email: d.customerEmailAddress,
                                phoneNumber: d.customerMobilePhone,
                                company: d.customerOrganization,
                                location: d.customerLocation,
                                postalCode: d.customerZipCode,
                                internacionalUser: d.customerInternacional
                            });

                            // -= SESSION =-
                            this.$store.commit("session", {
                                id: d.id,
                                key: d.sessionID,
                                name: d.sessionName,
                                createTime: d.createTime,
                                businessId: d.businessId,
                                companyId: d.companyId,
                                shopId: d.shopId,
                            });

                            // -= FAVORITES =-
                            if (d?.customerFavoriteShopProducts) {
                                d.customerFavoriteShopProducts.forEach((f) => {
                                    this.$store.commit("addToFavorites", {
                                        id: f.shopProductId,
                                        photoMainPath: f.productMainPhotoPath
                                    });
                                });
                            }

                            // -= SHOPPING CART
                            if (d?.shoppingCart) {
                                this.$store.commit("shoppingCart", d.shoppingCart.id);
                            }

                            if (d?.shoppingCart?.products) {
                                d.shoppingCart.products.forEach((p) => {
                                    p.rule = global.getUnitsRule(p.unitLoads)
                                    let item = global.getPriceTableItemByQuantity(p.tablePrices.tablePrices, p.quantity);
                                    if (!item || Object.keys(item).length == 0)
                                        item = global.getPriceTable(p.tablePrices.tablePrices).table[0];
                                    let quantities = global.getQuantityByPallets(item.UnitLoad, p)
                                    console.log("CORES")
                                    console.log(p.material)
                                    this.$store.commit("addToCart", {
                                        id: p.shopProductId,
                                        photoMainPath: p.shopProductMainPhotoPath,
                                        quantity: p.quantity,
                                        name: p.shopProductName,
                                        description: p.shopProductRefCode,
                                        totalPrice: p.totalPrice,
                                        extrasPrice: p.extrasPrice,
                                        finalPrice: p.finalPrice,
                                        cartProductId: p.id,
                                        measure: p.measure,
                                        availableMaterials: p.availableMaterials,
                                        availableColors: p.material.availableColors,
                                        availableExtras: p.availableExtras,
                                        color: p.color,
                                        extra: p.extras.length > 0 ? p.extras[0] : null,
                                        material: { ...p.material, associatedShopProductId: p.shopProductId },
                                        rule: p.rule,
                                        tablePrices: p.tablePrices,
                                        dose: quantities.dose,
                                        minimumUnits: p.productMinimumUnits == undefined ? 0 : p.productMinimumUnits,
                                        minimumBoxes: (p.productMinimumUnits === null || (p.productMinimumUnits ?? 0) <= 0) ? 0 : global.getQuantityByUnit(p.productMinimumUnits ?? 0, p.rule).boxes
                                    })
                                });
                            }
                            else
                                this.$store.commit("emptyCart");
                        }
                        this.audit("Login efectuado", this.username);
                        router.push({ name: "Categories" });
                    }
                    else {
                        this.errorMessage = r.data.message;
                        //this.audit("Erro ao efetuar login", r?.data?.message ?? "");
                        this.loginInterface("", r.data.messageCode, r.data.messageDetails)
                    }
                    this.isLoading = false; //hides de loading shape                    

                } catch (error) {
                    if (error.response) {
                        errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
                        lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
                        //this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
                    } else if (error.request) {
                        lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
                        //this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
                    } else {
                        lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
                        //this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
                    }
                }
            } else {
                this.errorMessage = "A username and password must be present";
                this.isLoading = false; //hides de loading shape
            }
        },

        // Get collection of translations texts form specific language
        async getContentForLanguage(languageId) {
            this.$store.commit("language", languageId);
            let response = await axios.get('/products/translation.json?date=' + new Date().getTime())
            if (response) {
                let allTranslations = response.data.SiteLabels.Labels;
                const labels = Array.from(allTranslations);
                const content = {};
                labels.forEach(function (label) {
                    const texts = label.Texts.filter(function (text) {
                        return text.LanguageId === languageId;
                    });
                    if (texts.length > 0) {
                        content[label.Name] = texts[0].Text;
                    }
                });
                this.$store.commit("translations", content);
                this.browserLang = languageId //update Interface
                //alert(languageId)
            }
        }
    },

    computed: {

        passwordStrengthMessage() {
            return `${this.passwordStrengthScore}`;
        },

    },

    async beforeMount() {

        this.detectLanguage()
        //this.loginInterface('', '102')
        //this.loginInterface('', 'errorOnChangePW')
        //this.getContentForLanguage(this.$store.state.selectedLanguageId); // carrega a primeira linguagem definida

    }

};
</script>