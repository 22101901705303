import { createStore } from "vuex";
import config from "../config";
import lib from "./library";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      version: config.version, // latest version
      isDebug: config.isDebug, // show output messages if activated
      isBetaFeature: config.isBetaFeature, // show elements pertaining to Beta Features in development
      hasAudit: config.hasAudit, // show output messages if activated
      culture: config.culture, // decimals format for differents cultuures
      currency: config.currency, //  currency unit to be used
      email: config.email,
      email2: config.email2,
      availableLanguages: config.availableLanguages,
      selectedLanguageId: 1,
      isAuthenticated: false,
      selectedCategoryId: null,
      selectedSubcategoryId: null,
      selectedProductCategoryId: null,
      categories: [],
      subcategories: [],
      products: [],
      productCategories: [],
      user: {},
      session: {},
      translations: {},
      colors: [],
      materials: []
    };
  },
  mutations: {
    colors(state, colors) {
      state.colors = colors;
    },
    materials(state, materials) {
      state.materials = materials;
    },
    translations(state, translations) {
      state.translations = translations;
    },
    language(state, langId) {
      state.selectedLanguageId = langId;
    },
    session(state, session) {
      state.session = session;
    },
    login(state, isLogged) {
      state.isAuthenticated = isLogged;
    },
    user(state, user) {
      state.user = user;
    },
    cart(state, cartItems) {
      state.user.cartItems = cartItems;
    },
    shoppingCart(state, id) {
      if (!state.user.shoppingCart) state.user.shoppingCart = {};
      state.user.shoppingCart.id = id;
    },
    addToCart(state, p) {
      if (!state.user.shoppingCart) state.user.shoppingCart = {};
      if (!state.user.shoppingCart.cartItems)
        state.user.shoppingCart.cartItems = [];

      lib.write(p, "add to cart (store)");
      state.user.shoppingCart.cartItems.push(p);
      lib.write(state.user.shoppingCart.cartItems);
    },
    updateCart(state, data) {
      const p = state.user.shoppingCart.cartItems.find((i) => i.id === data.id);

      lib.write(p, "update cart (store)");
      lib.write(
        "id, quantities, color, totalPrice : " +
          data.id +
          ", " +
          data.quantities +
          ", " +
          data.color +
          ", " +
          data.totalPrice
      );

      if (p) {
        if (data.quantities !== null) {
          if (data.quantities.units) p.quantity = data.quantities.units;          
          if (data.quantities.dose) p.dose = data.quantities.dose;
        }
        if (data.color !== null) p.color = data.color;
        if (data.totalPrice !== null) p.totalPrice = data.totalPrice;
      }
    },
    removeFromCart(state, id) {
      for (let i = 0; i < state.user.shoppingCart.cartItems.length; i++) {
        if (state.user.shoppingCart.cartItems[i].id === id) {
          state.user.shoppingCart.cartItems.splice(i, 1);
        }
      }
    },
    emptyCart(state, id) {
      if (id > 0)
        state.user.shoppingCart.id = id;          
        state.user.shoppingCart.cartItems = [];
    },
    addToFavorites(state, f) {
      if (!state.user.favorites) state.user.favorites = [];
      state.user.favorites.push(f);
    },
    removeFromFavorites(state, id) {
      if (state.user.favorites) {
        for (let i = 0; i < state.user.favorites.length; i++) {
          if (state.user.favorites[i].id === id) {
            state.user.favorites.splice(i, 1);
          }
        }
      }
    },
    categories(state, categories) {
      state.categories = categories;
    },
    subcategories(state, subcategories) {
      state.subcategories = subcategories;
    },
    productCategories(state, productCategories) {
      state.productCategories = productCategories;
    },
    productSelectedMaterial(state, data) {
        let p = state.products.find((i) => i.id === data.id);

        if (p)
         p.material = data.material;
        else
          lib.write(data.id, "produto não encontrado ao mudar material");
    },
    products(state, products) {
      state.products = products;
    },
    selectedCategory(state, id) {
      state.selectedCategoryId = id;
    },
    selectedSubcategory(state, id) {
      state.selectedSubcategoryId = id;
    },
    selectedProductCategory(state, id) {
      state.selectedProductCategoryId = id;
    },
    reset(state) {
      state.user = {};
      state.selectedCategoryId = null;
      state.selectedSubcategoryId = null;
      state.selectedProductCategoryId = null;
      state.isAuthenticated = false;
    },
  },
});

export default store;
