import store from "./store";

// Converte a fraction string like 1/2 to his float value, in this case 0.5
const convertFractionToNumber = (fraction) => {
    fraction = fraction.trim();
    const [numerator, denominator] = fraction.split('/');
    const parsedNumerator = parseFloat(numerator);
    const parsedDenominator = parseFloat(denominator);
    if (Number.isNaN(parsedDenominator) && !Number.isNaN(parsedNumerator))
        return parsedNumerator;

    if (parsedDenominator <= 0 || parsedNumerator <= 0 || Number.isNaN(parsedNumerator) || Number.isNaN(parsedDenominator)) {
        return 0;
    }

    const result = parsedNumerator / parsedDenominator;
    return parseFloat(result.toFixed(2));
}

const getFormatedCurrency = (price) => !price ? "0,00 €" : new Intl.NumberFormat(store.state.culture, {
    style: 'currency',
    currency: store.state.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    currencyDisplay: 'symbol',
}).format(price).replace(/\u00A0/g, ' ')

const getFormatedNumber = (number) => !number ? "0" : new Intl.NumberFormat("pt-PT", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    useGrouping: true,
}).format(number).replace(/\u00A0/g, ' ').replace(",0", "")

// Write simple console message only when needed
const write = (message, title) => {
    if (store.state.isDebug) {
        if (title)
            console.log("\n\n[" + title + "]");
        console.log(message);
    }
};

// Translated text using current selected language
const txt = (t) => {
    return store.state.translations[t] || ''; 
};

// Check if a specific id already exists inside of an array object
const belongs = (id, c, byAssociatedShopProductId) => {
    if (!c) return false;
    if (byAssociatedShopProductId)
        return c.some((p) => p.color.associatedShopProductId == id);
    else
        return c.some((p) => p.id == id);
};

export default { getFormatedCurrency, write, txt, belongs, convertFractionToNumber, getFormatedNumber };
