import "bootstrap/dist/css/bootstrap.css";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./common/store"


createApp(App)
  .use(router)
  .use(store)
  .mount("#app");
