<template>
  <div id="cart" class="bck-app">

    <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />


    <!-- header -->
    <div class="container-fluid">
      <div class="row">
        <!-- Col Start // Logo -->
        <div class="col-6 col-md-2">
          <!-- Logo -->
          <div id="branding" class="branding branding-light sticky-top" @click="goToHome"></div>
        </div>


        <!-- Cart Progress -->
        <div class="col-12 col-md-8 d-flex align-items-center justify-content-center">

          <div id="cartProgressSteps" class="step-wrapper font-01"
            v-if="$store.state.user?.shoppingCart?.cartItems?.length !== 0">

            <div data-step="step-01" class="step-item step-active"
              @click="cartStepUI('step-01'), cartInterface('cart')">
              <div class="step-item-flex">
                <div class="step-count-wrapper">
                  <div class="step-count-back"></div>
                  <div class="step-count"><i class="fa fa-check text-light"></i></div>
                </div>
                <div class="step-label">
                  <div> <i class="fa fa-cart-shopping"></i>
                    <!-- <div>Cart</div> -->
                    <div>{{ lib.txt("cart_Cart") }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div data-step="step-02" class="step-divider">
              <div class="step-divider-bar"></div>
            </div>

            <div data-step="step-02" class="step-item" @click="cartShippingIsClickable()">
              <div class="step-item-flex">
                <div class="step-count-wrapper">
                  <div class="step-count-back"></div>
                  <div class="step-count">

                    <i v-if="disableShipping" class="fa-solid fa-ban fa-2x color-orange"></i>
                    <i v-if="!disableShipping" class="fa fa-check text-light"></i>

                  </div>
                </div>
                <div class="step-label">
                  <div>
                    <i v-if="disableShipping" class="fa fa-warning color-orange mr-2"></i>
                    <i class="fa fa-truck"></i>

                    <div v-if="disableShipping">
                      <!-- Selection of shipping only available for 1 pallet or more -->
                      <span class="color-orange" v-if="!userIsInternacional"><b>
                          {{ lib.txt("shipping_DisabledNotice") }}
                        </b>
                      </span>
                      <span class="color-orange" v-if="userIsInternacional"><b>
                          Selection of shipping is not available for Internacional Users
                        </b>
                      </span>
                    </div>
                    <div v-if="!disableShipping">
                      <!-- Shipping -->
                      {{ lib.txt("shipping_txtShipping") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-step="step-03" class="step-divider">
              <div class="step-divider-bar"></div>
            </div>

            <div data-step="step-03" class="step-item">
              <div class="step-item-flex">
                <div class="step-count-wrapper">
                  <div class="step-count-back"></div>
                  <div class="step-count"><i class="fa fa-check text-light"></i></div>
                </div>
                <div class="step-label">
                  <div> <i class="fa fa-box"></i>
                    <div>
                      <!-- Order! -->
                      {{ lib.txt("shipping_txtOrder") }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>



        </div>

        <!-- Col Start // User Area Link -->


        <div class="col-6 col-md-2 d-md-block d-flex align-items-center justify-content-center">

          <!-- User Area Icon-->
          <div class="mt-0 mt-lg-5 me-lg-5 me-0 sticky-top">
            <div class="text-end">
              <div id="iconUserArea" class="icon-user-area icon-user-dark" @click="goToUserArea">
                <i class="fa fa-user"></i>
                <div class="icon-animate"></div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- Cart Contents -->
    <div class="container-fluid g-0 g-md-3">
      <!-- Informação Cart Vazio -->
      <div class="row" v-if="$store.state.user?.shoppingCart?.cartItems?.length == 0 && !orderSentInfo">
        <div class="col-12 d-flex justify-content-center">
          <div class="empty-item-notify">
            <img src="products/editable/cart-svgrepo-com-c.svg" alt="" style="width: 100px;" />
            {{ lib.txt("noCartItems") }}
          </div>
        </div>
      </div>

      <!-- Informação Pedido Enviado -->
      <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn animate__faster"
        leave-active-class="animate__animated animate__hide" mode="out-in">
        <div class="row" v-if="orderSentInfo">
          <div class="col-12 d-flex justify-content-center">
            <div class="empty-item-notify bg-success text-white">
              <img class="icon-truck" src="products/editable/delivery-truck-with-packages-behind.svg" alt=""
                style="width: 100px;" />
              {{ lib.txt("orderSuccess") }}
              <p>
                <br />{{ lib.txt("orderNumber") }}<br />
                <span class="badge badge-light text-dark m-2 p-2 w-100">{{ orderCode }}</span>
              </p>
            </div>
          </div>
        </div>
      </Transition>

      <!-- Informação Erro ao Enviar Pedido -->
      <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn animate__faster"
        leave-active-class="animate__animated animate__hide" mode="out-in">
        <div class="row" v-if="orderErrorInfo">
          <div class="col-12 d-flex justify-content-center">
            <div class="empty-item-notify bg-danger text-white">
              <img class="icon-deliveryError" src="products/editable/package-delivery-error.svg" alt=""
                style="width: 100px;" />

              {{ lib.txt("orderErrorInfo") }}

              <button type="button" class="button-select mt-4 w-25" @click="this.cartInterface('cart')">
                <i class="fa fa-arrow-left-long"></i> {{ lib.txt("backToCart") }}
              </button>

            </div>
          </div>
        </div>
      </Transition>

      <!-- Informação Erro Alteração de Preços Encontrada -->
      <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn animate__faster"
        leave-active-class="animate__animated animate__hide" mode="out-in">
        <div class="row" v-if="orderPriceChange">
          <div class="col-12 d-flex justify-content-center">
            <div class="empty-item-notify bg-secondary text-white">
              <img class="icon-deliveryError" src="products/editable/package-delivery-error.svg" alt=""
                style="width: 100px;" />

                <div v-html="lib.txt('orderPriceChange')"></div>

             

              <button type="button" class="button-select mt-4 w-25" @click="this.cartInterface('cart')">
                <i class="fa fa-arrow-left-long"></i> {{ lib.txt("backToCart") }}
              </button>

            </div>
          </div>
        </div>
      </Transition>

      <!-- PopUp Modal-->
      <div v-if="isPopUp" class="popUp-wrapper">
        <div id="popUp" class="popUp-container">
          <!-- Close Modal BOX-->
          <div class="popUp-close" @click="isPopUp = !isPopUp">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>


      <!-- <div>{{$store.state.user.customerId}} | {{ userIsInternacional }}</div> -->

      <!-- Cart Items and Totals -->
      <div v-if="$store.state.isDebug">
        <b>noShipping</b>: [{{ noShipping }}] <b>withShipping</b>: [{{ withShipping }}]
        <hr>
       
      </div>

      <div class="row m-0" v-if="cartSection && !orderSentInfo && $store.state.user.shoppingCart?.cartItems.length > 0">

        <div class="col-12  col-md-12 col-lg-7 offset-lg-1">

          <!-- Shipping Interface -->
          <div class="container" v-if="cartShipping">

            <!-- Table Prices + Map -->
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4">

                <!-- Table Prices Start -->
                <table class="table table-custom font-small" style="display: block; width: 100%;">
                  <thead>
                    <tr class="border-radius-control-top">
                      <th scope="col">{{ lib.txt("Pallets") }}</th>
                      <th scope="col" v-for="(z, idx) in zones" :key="idx">
                        <!-- Price / Pallet  -->
                        {{ lib.txt("tablePricePallet") }} {{ z.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(t, idx) in tableShippingPrices" :key="idx">
                      <td scope="row">{{ t.Description }}</td>
                      <td>{{ t.AreaA }} €</td>
                      <td>{{ t.AreaB }} €</td>
                      <td>{{ t.AreaC }} €</td>
                      <td v-if="t.AreaD">{{ t.AreaD }} €</td>
                      <td v-if="t.AreaE">{{ t.AreaE }} €</td>
                      <td v-if="t.AreaF">{{ t.AreaF }} €</td>
                    </tr>
                  </tbody>
                </table>

                <!-- Map Shipping -->
                <div class="d-flex align-items-center justify-content-center mb-3">
                  <div class="map-shipping-pt p-3  item-shadow" style="background-image: url(Assets/icons/Transportes2_StorePF_PT.svg); 
                                background-repeat: no-repeat;
                                background-position: center center; 
                                background-size: contain;">

                    <div v-if="mapLabels" class="map-shipping-labels-wrapp font-01">

                      <template v-for="(z, idx) in zones" :key="`zonediv-${idx}`">

                        <div class="d-flex flex-column">
                          <span class="map-pin">{{ z.name }}</span>
                        </div>

                      </template>

                    </div>

                    <div class="map-shipping-wrapp w-25">

                    </div>

                    <div v-if="mapLabels" class="map-shipping-labels-wrapp  font-01">

                      <template v-for="(z, idx) in zones" :key="`zonediv-${idx}`">

                        <div class="d-flex flex-column border-0">
                          <span class="font-01 map-description"
                            v-html="z.description.replace(/(\\r)*\\n/g, '<br>')"></span>
                        </div>

                      </template>

                    </div>
                  </div>
                </div>

              </div>

              <!-- Address List Section -->
              <div class="col">
                <div class="shipping-container item-shadow">

                  <div class="font-01 p-3 title-shipping d-md-flex justify-content-between">
                    <div>
                      <!-- label Shipping -->
                      {{ lib.txt("shipping_txtShipping") }}
                      <div v-if="$store.state.isDebug">
                        | Selected Address <div class="badge bg-danger">{{ selectedShippingAddressId }} | {{ addressList
                          }}
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- Button Group -->
                  <div v-if="buttonActive === ''">
                    <div class="cart-item-notice-box font-weight-bold">
                      <i class="fa fa-warning item-shadow-strong"></i>
                      <!-- Please Select a Shipping method to continue your order -->
                      {{ lib.txt("shipping_SelectMethod") }}
                    </div>
                  </div>

                  <div class="d-md-flex  p-3">

                    <!-- With Shipping -->
                    <div class="button-select border-info"
                      :class="{ 'button-select-active': buttonActive === 'withShipping' }" @click="btnWithShipping()">
                      <div class="label">
                        <div><b>
                            {{ lib.txt("shipping_withShipping") }}
                            <!-- With Shipping -->
                          </b></div>
                        <i class="fa fa-truck color-blue"></i>
                      </div>
                      <div class="button-shade">
                      </div>
                    </div>

                    <!-- Spacer -->
                    <div class="w-25"></div>

                    <!-- No Shipping -->
                    <div class="button-select border-info"
                      :class="{ 'button-select-active': buttonActive === 'withoutShipping' }">

                      <div class="label" @click="btnNoShipping()">
                        <div><b>
                            <!-- Without Shipping -->
                            {{ lib.txt("shipping_withoutShipping") }}
                          </b></div>
                        <i class="fa-solid fa-ban" style="color:Tomato"></i>
                      </div>
                      <div class="button-shade"></div>
                    </div>

                  </div>

                  <div class="address-wrap">



                    <!-- No Shipping Layout-->
                    <div v-if="buttonActive === 'withoutShipping'" class="position-relative">
                      <div class="noShippingMessage">
                        <!--You will pick your order at Plásticos Futura address-->
                        {{ lib.txt("shipping_PickupAtStore") }}
                      </div>
                      <img src="Assets/icons/noShipping_artwork.jpg" alt="">
                    </div>

                    <!-- Add New Shipping Address FORM-->
                    <transition name="custom-classes"
                      enter-active-class="animate__animated animate__bounceInDown animate__faster "
                      leave-active-class="animate__animated animate__backOutUp animate__faster">
                      <div class="m-2 position-relative" v-if="newAddressForm">


                        <!-- Is loading section -->
                        <div id="isLoadingNewAddress" class="isLoading-Zone" v-if="isLoadingNewAddress">
                          <div class='loader-wrapper'>
                            <div class='loader text-center'>
                              <div class='spinner'></div>
                              <p>{{ lib.txt("Updating") }}</p>
                            </div>
                          </div>
                        </div>



                        <!-- newAddressForm Container-->
                        <form>
                          <div class="shipping-address-container text-dark bck-System px-4 pt-3 shadow">

                            <div class="popUp-close popUp-close-sm" @click="newAddressForm = false">
                              <i class="fa fa-times"></i>
                            </div>

                            <!-- Input: Organization -->
                            <div class="mb-3 row font-01">
                              <label for="organization" class="col-sm-2 col-form-label">
                                <!-- Organization  -->
                                <b>{{ lib.txt("shipping_OrganizationAddress") }}:</b>
                              </label>
                              <div class="col-sm-10">
                                <input v-model='newAddress.organization' type="text" class="form-control"
                                  id="organization" required>
                                <div v-if="!newAddress.organization" class="addressRequired"><i
                                    class="fa fa-exclamation-triangle"></i></div>

                              </div>
                            </div>

                            <!-- Input: Morada -->
                            <div class="mb-3 row font-01">
                              <label for="morada" class="col-sm-2 col-form-label">
                                <!-- Morada: -->
                                {{ lib.txt("shipping_MoradaAddress") }}
                              </label>
                              <div class="col-sm-10">
                                <input v-model='newAddress.addressStreet' type="text" class="form-control" id="morada">
                                <div v-if="!newAddress.addressStreet" class="addressRequired"><i
                                    class="fa fa-exclamation-triangle"></i></div>
                              </div>
                            </div>

                            <!-- Input: City + Post Code -->
                            <div class="row g-3 align-items-center mb-3">

                              <div class="col-2">
                                <label for="city" class="col-form-label">
                                  <!-- City: -->
                                  {{ lib.txt("shipping_CityAddress") }}
                                </label>
                              </div>
                              <div class="col-6">
                                <input v-model='newAddress.addressCity' type="text" id="city" class="form-control">
                                <div v-if="!newAddress.addressCity" class="addressRequired"><i
                                    class="fa fa-exclamation-triangle"></i></div>
                              </div>
                              <div class="col-2">
                                <label for="cpostal" class="col-form-label">
                                  <!-- C.Postal: -->
                                  {{ lib.txt("shipping_PostalAddress") }}
                                </label>
                              </div>
                              <div class="col-2">
                                <input v-model='newAddress.addressZipCode' type="text" id="cpostal"
                                  class="form-control">
                                <div v-if="!newAddress.addressZipCode" class="addressRequired"><i
                                    class="fa fa-exclamation-triangle"></i></div>
                              </div>

                            </div>


                            <!-- Zona Picker -->
                            <div class="mb-3 row font-01 d-flex align-items-center">
                              <label for="inputPassword" class="col-sm-2 col-form-label">{{ lib.txt("shipping_ZoneTxt")
                                }}</label>
                              <div class="col-sm-3 d-flex align-items-center justify-content-center mb-3 m-md-0">

                                <div v-for="(z, idx) in zones" :id="`zonebtn-${idx}`" :key="`zonediv-${idx}`"
                                  class="btn-group item-shadow-strong  bg-white" role="group"
                                  aria-label="Basic radio toggle button group">
                                  <input type="radio" class="btn-check" name="btnradio" :value="z.id"
                                    :id="`inptzone-${idx}`" @click="() => newAddress.addressAreaId = z.id"
                                    :checked="newAddress.addressAreaId == z.id">
                                  <label class="btn btn-outline-info mb-0" :for="`inptzone-${idx}`">
                                    {{ z.name.split(' ')[1] }}
                                  </label>
                                </div>

                              </div>

                              <!-- Add Shipping Address Button-->
                              <div class="col-12 col-md-7" v-if="newAddress.organization &&
                                newAddress.addressStreet &&
                                newAddress.addressZipCode &&
                                newAddress.addressCity">
                                <div class="button-select bck-babyBlue">
                                  <div class="label" @click="saveShippingAddress()">
                                    <!-- Add new shipping address-->
                                    <div>{{ lib.txt("shipping_NewShippingAddress") }}</div>
                                    <i class="fa fa-plus color-blue">
                                    </i>
                                  </div>
                                  <div class="button-shade"></div>
                                </div>
                              </div>
                              <div class="col-12 col-md-7" v-else>
                                <div class="button-select bck-Yellow disabled">
                                  <div class="label">
                                    <!-- Add new shipping address-->
                                    <div>{{ lib.txt("shipping_NewShippingAddress") }}</div>
                                    <i class="fa fa-plus color-blue">
                                    </i>
                                  </div>
                                  <div class="button-shade"></div>
                                </div>
                              </div>


                            </div>

                          </div>
                        </form>

                      </div>
                    </transition>


                    <!-- Shipping Adresses-->
                    <div id="addressListGroup" v-show="buttonActive === 'withShipping'"
                      @click="submitOrderCheck = false">

                      <transition name="custom-classes"
                        enter-active-class="animate__animated animate__fadeIn animate__faster animate__delay"
                        leave-active-class="animate__animated animate__fadeOut animate__faster">
                        <div v-if="!newAddressForm"
                          class="align-items-baseline bck-System border-0 button-simple-outline color-orange d-flex-centered font-weight-bold m-2 p-2"
                          @click="btnAddAdress()">
                          <!-- Add new Shipping Address  -->
                          {{ lib.txt("shipping_addNewShippingAddress") }}
                          <i class="fa fa-plus ms-2"></i>
                        </div>
                      </transition>

                      <div v-for="(i, idx) in addresses" :id="`address-${i.id}`" @click="selectedAddressChanged(i, idx)"
                        class="shipping-address-container m-2" style="display:block" :key="i.id">

                        <div class="shipping-address-item">

                          <!-- Radio Button -->
                          <div class="shipping-adress-checked-container">
                            <input :id="`chkAddress-${i.id}`" class="form-check-input shipping-check" name="address"
                              type="radio" :value="i.id" v-model="selectedShippingAddressId">
                          </div>

                          <!-- Full Address -->
                          <div class="shipping-addressGroup font-01">
                            <div class="shipping-companyName">
                              <b>{{ i.organization }}</b>
                              <div v-if="$store.state.isDebug" class="badge badge-danger">{{ i.id }}</div>
                            </div>
                            <div class="shipping-address">{{ i.address.street }}</div>
                            <div class="shipping-postalCode">{{ i.address.zipCode }}</div>
                            <div class="shipping-zone">{{ i.address.addressAreaName }}</div>
                          </div>

                          <!-- Delete / Trash Address -->

                          <div :class="{ 'disabled': idx === 0 }" class="shipping-delete"
                            @click="addressManager(i.id, 'delete')">
                            <i class="fa fa-trash color-orange"></i>
                          </div>

                          <!-- Delete Confirmation -->
                          <div :id="`addressTrash-${i.id}`" class="addressTrash-container p-2" style="display:none">
                            <div class="addressButtons-container">

                              <div class="button-select bck-babyBlue m-2" @click.stop="deleteShippingAddress(i, idx)">
                                <div class="label">
                                  <!-- Delete this Address-->
                                  <div>{{ lib.txt("shipping_DeleteThisAddress") }}</div><i class="fa fa-trash "></i>
                                </div>
                              </div>


                              <div class="button-select bck-Yellow m-2"
                                @click.stop="addressManager(i.id, 'cancelDelete')">
                                <div class="label">
                                  <!-- Cancel -->
                                  <div>{{ lib.txt("btn_Cancel") }}</div><i class="fa fa-x"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>

          <!-- Grid Prod List-->
          <div class="footer-margin-height" v-if="cartItemSection">
           
            <template v-for="p in $store.state.user.shoppingCart.cartItems" :key="p.id">

              <!-- ## PopUp COLOR Modal ##-->
              <!-- CartProductId={{p.cartProductId}} -->
              <div v-bind:id="'color-Modal-' + p.cartProductId" class="popUp-wrapper" style="display:none">
                <div class="popUp-container">
                  <!-- Close Modal BOX-->
                  <div class="popUp-close" @click="isColorModal(p.cartProductId, 'close')">
                    <i class="fa fa-times"></i>
                  </div>

                  <!-- Content Options -->
                  <div class="popUP-frame">
                    <h5>
                      {{ lib.txt("availableColors") }}
                      <span v-if="$store.state.isDebug" class="badge font-weight-lighter rounded-pill text-bg-light">
                        p.cartProductId: {{ p.cartProductId }}
                      </span>
                    </h5>
                    <hr />
                    <!-- Colors Options-->
                    <div class="form-options-format">
                      <!-- List of options -->
                      <div class="form-check" v-for="c in p.availableColors" :key="c.id">

                        <!-- v-bind:class="p.id+'-'+c.code" -->
                        <div v-bind:class="p.description + '-' + c.code"
                          class="form-check-label item-shadow color-select"
                          @click="colorChanged(c, p), findUsedColors(c.code, p.description, $event)">
                          <!-- Color Badge-->
                          <i v-if="c.rgb != null" class="fa fa-circle item-shadow-filter-inv"
                            :style="{ color: 'rgb(' + c.rgb + ')', }"></i>
                          <i v-else class="fa fa-circle item-shadow-filter-inv"
                            :style="{ color: 'rgb(255,255,255)' }"></i>
                          <span>{{ $store.state.colors.find(color => color.id === c.id).name }}</span>
                          <span><!-- {{ c.code }} --></span>

                          <span v-if="$store.state.isDebug"
                            class="badge font-weight-lighter rounded-pill text-bg-light">
                            {{ p.description }} | {{ c.code }} | {{ c.id }}
                          </span>

                        </div>

                        <div class="infobox-color-attach" v-if="!!c.obs">
                          <div><i class="fa-solid fa-brush color-baby-blue px-3"></i></div>
                          <div>{{ c.obs }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ## Personalization Color Modal ## -->

              <div v-bind:id="'customize-Modal-' + p.id" class="popUp-wrapper" style="display:none">
                <div class="popUp-container">
                  <!-- Close Modal BOX-->
                  <div class="popUp-close" @click="isCustomModal(p.id, 'close')">
                    <i class="fa fa-times"></i>
                  </div>

                  <!-- Content Options -->
                  <div class="popUP-frame">
                    <!-- Personalização Disponível -->
                    <h5>{{ lib.txt("custom_AvailablePersonalizations") }}</h5>
                    <hr />
                    <!-- Customization Options-->

                    <div class="form-options-format">
                      <div class="form-check">
                        <div class="form-check-label item-shadow" @click="customChanged(null, p)">
                          <span>{{ lib.txt("noCustomSelected") }}</span>
                        </div>
                      </div>
                      <div class="form-check" v-for="(e) in p.availableExtras ?? []" :key="e.id">
                        <div class="form-check-label item-shadow" @click="customChanged(e, p)">
                          <span>{{ e.name }}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="cart-item-wrapper container item-shadow">

                <!-- Is loading section -->
                <div v-bind:id="'isLoading-' + p.id" class="isLoading-Zone" style="display:none">
                  <div class='loader-wrapper'>
                    <div class='loader text-center'>
                      <div class='spinner'></div>
                      <!-- <p>Updating</p> -->
                      <p>{{ lib.txt("info_Updating") }}</p>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <!-- Photo -->
                  <div class="cart-prodImage col-12 col-md-2 p-0 m-3 item-align-center prod-photo-bck"
                    :style="{ backgroundImage: `url(${buildPath(p.photoMainPath)})` }">
                    <!-- <img :src="buildPath(p.photoMainPath)" alt="" class="rounded-3" /> -->
                  </div>

                  <div class="col p-0 p-md-4">
                    <!-- ShopCart Option Selection -->
                    <div v-bind:id="'prodSelection-' + p.id">
                      <!-- -->
                      <div class="table-prodRef">
                        <!-- Product Description -->
                        <div class="me-4">
                          <div v-if="$store.state.isDebug">

                            <span class="badge badge-light font-weight-lighter text-black"><span
                                class="color-orange font-weight-bold">p.color.associatedShopProductId:</span> {{
                              p.color.associatedShopProductId }} </span>
                            <span class="badge badge-light font-weight-lighter text-black"><span
                                class="color-orange font-weight-bold">p.id:</span> {{ p.id }} </span>
                            <span class="badge badge-light font-weight-lighter text-black"><span
                                class="color-orange font-weight-bold">p.cartProductId:</span> {{ p.cartProductId }}
                            </span>
                            <span class="badge badge-light font-weight-lighter text-black"><span
                                class="color-orange font-weight-bold">shoppingCart.id:</span> {{
                              this.$store.state.user.shoppingCart.id }}</span>
                            <br />

                          </div>
                          <div>
                            <span class="table-prodTitle">
                              {{ p.name }}
                            </span>
                            <span class="table-prodDescription ml-2">{{ p.description }}</span>
                          </div>
                          <span class="badge border border-bottom me-2 text-dark">{{
                            getMaterialTranslated(p.material.id)
                            }}</span>
                          <span class="badge border border-bottom me-2 text-dark">max. {{ p.measure?.capacity ?? 0
                            }}ml</span>
                          <span class="badge border border-bottom me-2 text-dark">Ø{{ p.measure?.width ?? 0 }}x{{
                            p.measure?.height ?? 0 }}</span>

                        </div>

                        <!-- Product Material -->
                        <!-- <div class="item-materialName">
                                          <div class="item-badge item-light border">
                                            <b>{{p.material.name}}</b>
                                          </div>
                                        </div> -->
                        <!-- Spacer -->
                        <div class="flex-grow-1"></div>

                        <!-- Remote item from Cart -->
                        <div v-bind:id="'isTrash-' + p.id" class="icon-prod-remove me-2" @click="removeCartItem(p)">
                          <i class="fa fa-trash"></i>
                        </div>
                      </div>

                      <!-- Prod Options -->
                      <div class="w-100">
                        <div class="row m-0 p-0 position-relative">

                          
                          <div class="overlay-priceChange" v-if="checkProductStatus(p.cartProductId)">
                            <div class="message shadow font-01  ">
                              <div><i class="color-orange fa fa-3x fa-circle-exclamation"></i></div>
                              <div class="mt-3 px-5">
                                <span class="color-blue" v-html="lib.txt('orderPriceChangeCart')"></span>                               
                              </div>                              
                            </div>
                          </div>
                        

                          <!-- Quantity-->
                          <div class="col-12 col-lg-12 col-xl  table-custom-wrapp">
                            <div class="table-custom-titles">
                              <div>{{ lib.txt("quantity") }}</div>
                              <div class="fw-light font-note">
                                <!-- MORE QUANTITY - LOWER PRICE -->
                                {{ lib.txt("quantity_lowerPrice") }}
                              </div>
                            </div>

                            <div class="table-custom-content">
                              <div class="item-badge item-shadow item-dark form-Qt-Qty-padding">
                                <div class="form-quantity-labels">

                                  <div class="form-Qt-container">
                                    <div class="form-Qt-label">{{ lib.txt("units") }}</div>
                                    <div class="form-Qt-output" v-bind:id="'qt-units-' + p.id">
                                      {{ getFormatedNumber(p.quantity) }}
                                    </div>
                                  </div>

                                  <div class="form-Qt-container">
                                    <div class="form-Qt-label" v-html="lib.txt('dose')"></div>
                                    <div class="form-Qt-output form-Qt-outputWide" v-bind:id="'qt-palets-' + p.id">

                                      {{ p.dose }}

                                      <div class="form-Qt-dropdown">

                                        <i class="fa fa-chevron-circle-down" @click="isPalletDropdown(p.id, 'open')">
                                        </i>

                                        <!-- Dropdown construct -->

                                        <div v-bind:id="'qt-paletsUnit-' + p.id"
                                          class="form-Qt-dropdown-box animate__animated animate__fadeInLeft animate__faster"
                                          @click="isPalletDropdown(p.id, 'close')"
                                          @mouseleave="isPalletDropdown(p.id, 'close')" style="display:none">
                                          <div class="form-Qt-dropdownItems"
                                            v-for="t in getPriceTable(p.tablePrices.tablePrices).table" :key="t">
                                            <div @click="palletChanged(p, t.UnitLoad)">{{ t.UnitLoad }}</div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>



                                </div>



                                <!-- ▲▼ Increment Section -->
                                <!-- <div class="item-incrPos-Wrapper"> -->
                                <!-- ▲ Increment Step -->
                                <!-- <i class="fa fa-chevron-up item-increment item-incrPos-up item-click" @click="quantityChanged(p, p.boxes + 1)"></i> -->
                                <!-- ▼ Decrement Step -->
                                <!-- <i class="fa fa-chevron-down item-increment item-incrPos-down item-click" @click="quantityChanged(p, p.boxes - 1)" :class="p.boxes > p.minimumBoxes  ? '' : 'disabled'"></i> -->
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>

                          <!-- Color -->
                          <div class="col-12 col-lg-4 col-xl-3 table-custom-wrapp">
                            <div class="table-custom-titles">
                              <div>{{ lib.txt("color") }}</div>
                            </div>

                            <div class="table-custom-content">
                              <div class="color-selected item-badge item-shadow item-light item-align item-click py-3"
                                :name="p.description + '-' + p.color.code"
                                @click="isColorModal(p.cartProductId, 'open'), findUsedColors(p.color.code, p.id, $event)">
                                <!-- Color Circle-->

                                <i class="fa fa-circle me-2 item-shadow-filter"
                                  v-bind:id="'color-rgb-' + p.cartProductId"
                                  :style="{ color: 'rgb(' + p.color.rgb + ')', }">
                                </i>
                                <!-- Color Label-->
                                <div v-bind:id="'color-label-' + p.cartProductId">
                                  {{ $store.state.colors.find(x => x.id === p.color.id).name }}
                                </div>
                                <i class="fa fa-chevron-down"></i>
                              </div>
                            </div>



                            <!-- Info for Color-->
                            <div v-bind:id="'infobox-color-' + p.id" class="infobox-color" v-if="p.color.obs !== ''"
                              style="display:none">
                              <div><i class="fa-solid fa-brush color-baby-blue px-3"></i></div>
                              <div>{{ lib.txt("baseColorText") }}</div>
                            </div>


                          </div>

                          <!-- Personalized -->
                          <div class="col-12 col-lg-4 col-xl-2 table-custom-wrapp" v-if="p.availableExtras.length > 0">

                            <div class="table-custom-titles">
                              <!-- Personalização label-->
                              <div>{{ lib.txt("custom_Personalization") }}</div>
                            </div>

                            <!-- Personalized Dropdown  -->
                            <div class="table-custom-content">
                              <div class="item-badge item-shadow item-light item-align item-click py-3"
                                @click="isCustomModal(p.id, 'open')">

                                <!-- Icon Circle-->
                                <i class="fa fa-pen"></i>

                                <!-- Custom Item Label-->
                                <div v-bind:id="'custom-name-' + p.id">
                                  {{ p.availableExtras?.length > 0 && p.extra ? p.extra.name :
                                  lib.txt("noCustomSelected")
                                  }}
                                </div>
                                <i class="fa fa-chevron-down"></i>
                              </div>
                            </div>

                          </div>

                          <!-- Price -->
                          <div class="col-12 col-lg-4 col-xl-2 table-custom-wrapp">
                            <div class="table-custom-titles">
                              <div>{{ lib.txt("price") }}</div>
                            </div>
                            <!-- Price Tag-->
                            <div class="table-custom-content">
                              <span class="item-badge item-shadow item-number item-light  py-3">
                                <span v-bind:id="'price-tag-' + p.id">{{ lib.getFormatedCurrency(p.finalPrice) }}</span>
                              </span>
                            </div>
                          </div>

                          <!-- Add To Cart -->
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </template>
            

            <!-- Information -->

            <div class="cart-item-information item-shadow">

              <div class="cart-item-information-icon">
                <img class="img-fluid" src="products/editable/paint-brush-art-svgrepo-com.svg">
              </div>

              <div class="cart-item-information-text">
                <b>{{ lib.txt("otherColors") }}</b>{{ lib.txt("colorsRequest") }}
                <div class="mt-2">
                  <a class="linktype-email " :href="`mailto:${$store.state.email2}`">
                    <i class="fa fa-envelope"></i> {{ $store.state.email2 }}
                  </a>
                </div>
              </div>

            </div>

          </div>

        </div>

        <!-- Cart Item Checkout & Totals-->
        <div class="col-12 col-lg-3 bg-cart position-relative">

          <div class="checkout-container chk-container-light sticky-top">

            <!-- Is loading section -->
            <div class="isLoading-Zone" v-if="isLoadingPlacingOrder">
              <div class=loader-wrapper>
                <div class=loader>
                  <div class=spinner></div>
                  <p>{{ lib.txt("processingOrder") }}</p>
                </div>
              </div>
            </div>

            <!-- Cart Totals -->
            <div class="container g-0">
              <div class="row">
                <div class="col-12 font-01">
                  <div>
                    <div class="checkout-subtotal">{{ lib.txt("cart_SubTotal") }}:</div>
                    <div class="checkout-note"> {{ lib.txt("taxesInfo") }} </div>
                  </div>
                </div>

                <!-- items -->
                <div class="col-12 font-01">
                  <div class="checkout-edit"></div>
                  <div class="checkout-price-box-01">
                    <div class="checkout-prod-description" v-for="p in $store.state.user.shoppingCart.cartItems"
                      :key="p.id">

                      <div class="w-100">

                        <!-- Prod Name + Choices-->
                        <div class="w-100">
                          <div class="table-prodDescription">{{ p.name }}</div>
                          <div class="d-lg-flex justify-content-lg-between">
                            <div>
                              <div class="badge badge-light border-bottom color-blue mr-2"> {{
                                getFormatedNumber(p.quantity) }} un {{ p.dose }} Pal </div>
                              <div class="badge badge-light border-bottom color-blue">



                                <!-- Color Label-->
                                <div>
                                  <i v-if="p.color.rgb != null" class="fa fa-circle item-shadow-filter-inv me-2"
                                    :style="{ color: 'rgb(' + p.color.rgb + ')', }"></i>
                                  {{ $store.state.colors.find(x => x.id === p.color.id).name }}
                                </div>



                              </div>
                            </div>
                            <div class="badge badge-info color-white font-small w-auto"> <b>{{
                                lib.getFormatedCurrency(p.finalPrice) }}</b></div>
                          </div>
                          <div v-if="p.hasBoxes" class="badge badge-light color-orange color-red font-01 mt-2">
                            <i class="fa-solid fa-turn-up fa-rotate-90 mr-2"></i>
                            <!-- Opção de Transporte não disponível para caixas, só palete -->
                            {{ lib.txt("shipping_textForBoxes") }}
                          </div>
                        </div>

                        <!-- <div class="font-note"><b></b> {{ p.extra.name }} + {{ p.color.name }}</div> -->

                        <!-- Price -->
                      </div>

                    </div>
                    <div v-if="!noShipping" class="checkout-prod-description">
                      <div>{{ lib.txt("shipping_txtShipping") }} - {{ shippingPerPallet }} € / Pal </div>
                      <div> <b>{{ totalShipping }} €</b></div>

                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-lg-12 col-xxl-5 d-flex-centered mt-2 mt-sm-2 order-1 order-sm-1 position-relative">



                  <!-- back to cart button-->
                  <Transition name="custom-classes"
                    enter-active-class="animate__animated animate__fadeInRight animate__faster"
                    leave-active-class="animate__animated animate__fadeOutRight" mode="out-in">
                    <div class="bck-System button-edit" v-if="!cartItemSection"
                      @click="cartStepUI('step-01'), cartInterface('cart')">
                      <div class="label">
                        <i class="color-baby-blue fa fa-reply item-shadow-filter mr-2"></i>
                        <i class="fa fa fa-shopping-cart mr-2"></i>
                        <!-- Back to the Cart -->
                        <b>{{ lib.txt("cart_BackToCart") }}</b>
                      </div>
                      <div class="button-shade"></div>
                    </div>
                  </Transition>

                </div>

                <div class="col-12 col-lg-12 col-xxl-7 order-xxl-1">
                  <div class="checkout-price-box-02 color-blue text-right font-weight-bold font-xl py-2 text-right">
                    <div>
                      <div v-if="!noShipping">{{ lib.getFormatedCurrency(total) }} </div>
                      <div v-if="noShipping">{{ lib.getFormatedCurrency(totalNoShipping) }} </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- Shipping Order BTN -->

            <hr />

            <div v-if="!disableShipping">

              <div class="d-flex flex-column align-items-center" v-if="!orderSubmit">
                <button id="shipping_submit_Button" class="btn-white mt-3 mb-3 button-order"
                  @click="cartInterface('shipping')">
                  {{ lib.txt("shipping_txtShipping") }} <i class="fa fa-arrow-right font-note me-2"></i>
                  <i class="fa fa-truck font-note"></i>
                </button>
              </div>

            </div>

            <!-- Place Order Button -->
            <div v-if="orderSubmit">


              <div class="d-flex flex-column align-items-center" :class="{ disabled: buttonActive === '' }">

                <!-- Place Order -->
                <button :class="{ disabled: !submitOrderCheck }" id="order_submit_Button"
                  class="btn-white mt-3 mb-3 button-order" @click="placeOrder()">
                  <i class="fa fa-check"></i>{{ lib.txt("placeOrder") }}
                </button>

                <!-- CheckBox submit order check -->
                <div class="form-check form-check-confirmBox font-01">
                  <input v-model=submitOrderCheck class="form-check-input" type="checkbox" value=""
                    id="flexCheckDefault" @click="submitOrderCheck = !submitOrderCheck" />
                  <label class="text-warning text-center"> {{ lib.txt("orderConfirmation") }} </label>
                </div>
              </div>


            </div>

            <div class="checkout-note text-center">
              {{ lib.txt("orderInfo") }}
            </div>
            <hr>
            <div>
              <div class="messageBox-info font-01  pb-3">
                <i class="fa fa-comment"></i> {{ lib.txt("cartMessageFromUser") }}:
              </div>
              <textarea class="form-control font-01" v-model="associatedNote"></textarea>
            </div>

          </div>
        </div>

      </div>

      <!-- Spacer -->
      <div class="row mt-5"></div>

    </div>

    <!-- Footer Component-->
    <Footer />

  </div>
</template>

<script>
import ErrorPopup from './PopUpMsg';
import axios from "axios";
import router from "../router";
import lib from "../common/library"
import global from "../common/global"
import texts from "../common/translation"
import Footer from './Footer';
import { timingSafeEqual } from 'crypto';

export default {
  name: "Categories",
  components: { ErrorPopup, Footer },

  data() {
    return {
      audit: global.audit,
      productPanelShow: global.productPanelShow,
      isColorModal: global.isColorModal,
      isCustomModal: global.isCustomModal, //Popup Personalization
      buildPath: global.buildPath,
      cartStepUI: global.cartStepUI,
      addressManager: global.addressManager,
      getFormatedCurrency: global.getFormatedCurrency,
      isPalletDropdown: global.isPalletDropdown,
      getPriceTable: global.getPriceTable,
      getFormatedNumber: lib.getFormatedNumber,
      getColorTranslated: global.getColorTranslated,
      getMaterialTranslated: global.getMaterialTranslated,
      texts,
      lib,

      total: 0,
      totalShipping: 0,
      totalNoShipping: 0,
      totalPallets: 0,

      tableShippingPrices: [],
      rowShippingPrices: {},
      shippingPerPallet: 0,
      orderCode: null,
      addresses: [],
      zones: [],
      disabledCartItems: [],
      disabledCartItemsMSG: "", //tempItem

      showCartItems: true, //tempItem

      newAddress: {
        addressStreet: '',
        addressNumber: '',
        addressFloor: '',
        addressCity: '',
        addressZipCode: '',
        addressCountryId: 1,
        addressLatitude: '',
        addressLongitude: '',
        addressObs: '',
        addressAreaId: 1,
        phoneHome: '',
        phoneMobile: '',
        email: '',
        organization: ''
      },

      selectedShippingAddressId: null,

      //FORM Boxes
      isPopUp: false,
      cartPopUp: false,
      cartFavUp: false,
      associatedNote: "",

      isLoading: true, //loader for the Quantity change
      isLoadingPlacingOrder: false, //loader for the PlaceOrder wait status
      isLoadingNewAddress: false, //loader for the Adding New Address

      // Full Form visibility
      cartSection: true,
      cartItemSection: true,
      cartShipping: false,
      //Form Panels

      option_Boxes: false,
      option_Pallets: false,
      option_colors: false,

      //Disable Place Order Button
      submitOrderCheck: false,
      orderSentInfo: false,
      orderErrorInfo: false,
      orderPriceChange: false,
      orderSubmit: false,

      errorMessage: '',
      errorLog: '',

      //Shipping
      newAddressForm: false,
      addressList: true,
      noShipping: true, //shipping prices
      buttonActive: '', //With Shipping or no shipping Buttons State
      disableShipping: '', //When Boxes Selected, cancel Shipping
      withShipping: true, //PlaceOrder Default Param
      mapLabels: false,

      userIsInternacional: false // type of User

    };
  },

  // updated() {
  //   console.log("updated: addressList")
  //   if (this.addressList) {
  //     this.addressManager(1, "selectFirst", "updated")
  //   }
  // },

  watch: {

    // addressList(newVal) {
    //   console.log("watch: addressList")
    //   if (newVal === true) {
    //     this.$nextTick(() => {
    //       this.addressManager(0, "selectFirst", "updated");
    //       this.addressManager(0, 'select', 'updated');

    //     });
    //   }
    // },

    // cartShipping(newVal) {
    //   console.log("watch: cartShipping")
    //   if (newVal === true) {
    //     this.$nextTick(() => {
    //       this.addressManager(0, "selectFirst", "updated");
    //       this.addressManager(0, 'select', 'updated');
    //     });
    //   }
    // },

  },

  methods: {
    async deleteShippingAddress(m, idx) {

      let apiMethodName = "DeleteCustomerContact";
      let errorMsg = "";
      try {

        let r = await axios.post(
          `/api/Business/DeleteCustomerContact`, null, {
          params: {
            customerId: this.$store.state.user.customerId,
            contactId: m.id
          }
        })

        if (r?.data?.success) {
          this.addressManager(m.id, 'confirmDelete');
          this.audit("Morada apagada com sucesso");
          //this.addresses = this.addresses.filter((address) => address.id !== m.id); //
          //this.cartInterface('shipping')
        }
        else
          this.audit("Erro ao apagar morada", r?.data?.messageDetails ?? r?.data?.message ?? m.id);

      } catch (error) {
        if (error.response) {
          errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
          lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
          this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
          lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
          this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
          lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
          this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
      }
    },

    async saveShippingAddress() {

      let apiMethodName = "AddCustomerContactWithParameters";
      let errorMsg = "";
      try {
        this.buttonActive = 'withShipping';
        this.isLoadingNewAddress = true;
        let r = await axios.post(
          `/api/Business/AddCustomerContactWithParameters`, null, {
          params: {
            customerId: this.$store.state.user.customerId,
            addressStreet: this.newAddress.addressStreet ? this.newAddress.addressStreet : '-',
            addressNumber: this.newAddress.addressNumber ? this.newAddress.addressNumber : '-',
            addressFloor: this.newAddress.addressFloor ? this.newAddress.addressFloor : '-',
            addressCity: this.newAddress.addressCity ? this.newAddress.addressCity : '-',
            addressZipCode: this.newAddress.addressZipCode ? this.newAddress.addressZipCode : '-',
            addressCountryId: this.newAddress.addressCountryId,
            addressLatitude: this.newAddress.addressLatitude ? this.newAddress.addressLatitude : '-',
            addressLongitude: this.newAddress.addressLongitude ? this.newAddress.addressLongitude : '-',
            addressObs: this.newAddress.addressObs ? this.newAddress.addressObs : '-',
            addressAreaId: this.newAddress.addressAreaId,
            phoneHome: this.newAddress.phoneHome ? this.newAddress.phoneHome : '-',
            phoneMobile: this.newAddress.phoneMobile ? this.newAddress.phoneMobile : '-',
            email: this.newAddress.email ? this.newAddress.email : '-',
            organization: this.newAddress.organization ? this.newAddress.organization : '-'
          }
        }
        )

        if (r?.data?.success) {

          this.audit("Nova morada guardada com sucesso");
          this.addresses.push({
            id: r.data.responseData,
            phoneHome: this.newAddress.phoneHome,
            email: this.newAddress.email,
            organization: this.newAddress.organization,
            address: {
              addressAreaId: this.newAddress.addressAreaId,
              addressAreaName: '',
              city: this.newAddress.addressCity,
              countryId: this.newAddress.addressCountryId,
              countryName: 'Portugal',
              floor: this.newAddress.addressFloor,
              latitude: this.newAddress.addressLatitude,
              longitude: this.newAddress.addressLongitude,
              number: this.newAddress.addressNumber,
              obs: this.newAddress.addressObs,
              street: this.newAddress.addressStreet,
              zipCode: this.newAddress.addressZipCode,
            }

          })
        }
        else {
          console.log("erro:")
          console.log(this.newAddress.addressNumber ?? "-");
          this.audit("Erro ao guardar nova morada", r?.data?.messageDetails ?? r?.data?.message ?? "");
          this.errorMessage = "Erro ao guardar nova morada"
        }
        this.isLoadingNewAddress = false;
        this.newAddressForm = false

        await this.cartInterface('shipping');

        // Clear the new address Form 
        this.newAddress.organization = ''
        this.newAddress.addressStreet = ''
        this.newAddress.addressCity = ''
        this.newAddress.addressZipCode = ''

      } catch (error) {
        if (error.response) {
          errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
          lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
          this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
          lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
          this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
          lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
          this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
      }
    },

    // Amazing Interface Functions v0.5   
    isModal(targetForm, targetID) {
      // targetID is the element to be updated in the dom.
      //alert(targetID)
      this.currentTargetId = targetID;

      //shows the popUp
      this.isPopUp = true;

      //hides the forms Options from the modal

      this.option_Boxes = false;
      this.option_Pallets = false;
      this.option_colors = false;

      this[targetForm] = true;
    },

    async cartInterface(mode, debug) {

      console.log("cartInterface: " + mode + " Origin: " + debug)
      //alert(this.noShipping + " - " + mode)

      if (mode == "cart") {

        this.cartStepUI("step-01")
        this.cartItemSection = true;   //shows the cart main container
        this.cartSection = true;   //shows the cart sections / Items and Totals
        this.cartShipping = false; //hides the shipping sections
        //this.disableShipping = false;
        this.noShipping = true; //hides the shipping prices

        this.orderPriceChange = false; //hides the sucess info
        this.orderSentInfo = false; //hides the sucess info
        this.orderErrorInfo = false; //hides the Error info
        this.isLoadingPlacingOrder = false //hides the Waiting for processing

        this.submitOrderCheck = false // resets the confirmation box

        this.getTotalPallets()

      }

      if (mode == "showErrorOrder") {
        this.cartStepUI("step-01")
        this.orderPriceChange = false; //shows the Error info
        this.orderErrorInfo = true; //shows the Error info
        this.orderSentInfo = false; //shows the sucess info
        this.cartSection = false; //hides the form
        this.isLoadingPlacingOrder = false //shows the Waiting for processing
      }

      if (mode == "showErrorPrice") {
        this.cartStepUI("step-01")
        this.orderPriceChange = true; //shows the Error info
        this.orderErrorInfo = false; //shows the Error info
        this.orderSentInfo = false; //shows the sucess info
        this.cartSection = false; //hides the form
        this.isLoadingPlacingOrder = false //shows the Waiting for processing
      }

      if (mode == "showInfoOrder") {
        this.orderSentInfo = true; //shows the sucess info
        this.orderErrorInfo = false; //shows the Error info
        this.cartSection = false; //hides the form
        this.isLoadingPlacingOrder = false //shows the Waiting for processing
      }

      if (mode == "shipping") {
        console.log("mode == shipping")
        this.audit("Passo 2 checkout", 'Shipping');
        this.cartShipping = true;
        this.cartItemSection = false; //hides related Cart items
        this.disableShipping = false;
        this.addressList = false;
        this.noShipping = true; //hides the shipping prices
        this.orderSubmit = true;
        this.cartStepUI("step-02")
        this.getAddresses(true);
        this.buttonActive = '' // clear the Button Selection
      }

      if (mode == "shippingDisabled") {
        this.disableShipping = true;
        this.orderSubmit = true;
        this.btnNoShipping();
        // this.noShipping = true
        // this.withShipping = false;
        //CreateShopPurchaseOrder Param
        //alert("noShippingMode")
      }

      if (mode == "shippingEnabled") {
        this.disableShipping = false;
        this.orderSubmit = false;
        this.noShipping = true; //hides the shipping prices
        //this.btnWithShipping('skipInterface');
      }

    },

    cartShippingIsClickable() {
      if (this.disableShipping === false)
      {
          this.cartStepUI('step-02')
          this.cartInterface('shipping')
      }
    },    

    btnNoShipping(param) {

      this.audit("Método sem envio");

      this.noShipping = true;
      this.addressList = false;
      this.newAddressForm = false;
      this.buttonActive = 'withoutShipping';
      this.withShipping = false;
      this.selectedShippingAddressId = 0
      this.disableOrderButton();
    },

    btnWithShipping(param) {

      this.audit("Método com envio");
      this.addressList = true
      this.newAddressForm = false
      this.withShipping = true
      this.noShipping = false
      this.buttonActive = 'withShipping'

      // if (param != 'skipInterface' ) {
      //   this.noShipping = false,
      //   this.cartInterface('shipping')
      // }
    },

    btnAddAdress() {
      this.noShipping = false;
      this.addressList = true;
      this.newAddressForm = true;
      this.buttonActive = 'withShipping'
      this.audit("Adicionar nova morada");
    },

    selectedAddressChanged(a, idx) {
      this.addressManager(a.id, 'select', 'shippingAdressClick'),
        this.selectedShippingAddressId = a.id
      this.audit("Morada selecionada", a.id);
      this.totalShipping = parseFloat(global.getPricesForShippingByZoneId(a.address.addressAreaId, this.rowShippingPrices).replace(',', '.')) * this.totalPallets;
      //console.log("totais")
      //console.log(  this.totalShipping);
      //console.log( this.totalPallets)
      this.shippingPerPallet = this.totalShipping / this.totalPallets;
      this.updateTotalCart();

    },

    async palletChanged(p, dose) {

      //console.log("palletChanged()")

      global.isSectionLoading(p.id, 'show')

      await global.palletChanged(p, dose, true);
      this.updateTotalCart();
      this.disableOrderButton()
      global.isSectionLoading(p.id, 'hide')

      await this.GetShippingCostTable()
      p.hasBoxes = this.hasBoxes(dose ? dose.toLowerCase().trim() : '');

      this.getTotalPallets()

      //if (p.hasBoxes === true) {          
      //   this.cartInterface("noShipping", "palletChanged()")
      //}


    },

    findUsedColors(c, p, $event, sourceEvent) {
      let targetID = p
      let colorID = c

      //todo: needs targetID also
      let target = document.querySelectorAll("." + colorID);

      //find all elements in the dropdown modal
      let colorItem = document.querySelectorAll(".color-select");
      colorItem.forEach((find) => {
        find.classList.remove("disabled"); //changes the Button Color.
        //el.style.color = "red";
      });

      //find all already selected color items
      let colorSelectedItem = document.querySelectorAll(".color-selected");
      colorSelectedItem.forEach((find) => {

        let getTarget = find.attributes.name.value
        let testarray = document.getElementsByClassName(getTarget);

        for (var i = 0; i < testarray.length; i++) {
          testarray[i].className += " disabled";
        }

        console.log(getTarget)
      });

      //check for nulls
      //for each target adds class
      console.log(target)

    },

    async colorChanged(c, p) {
      // global.isSectionLoading(p.id, 'show')
      let r = await global.colorChanged(c, p, true);
      if (r)
        this.errorLog = r;
      this.updateTotalCart();
      this.disableOrderButton();
      // global.isSectionLoading(p.id, 'hide')
    },

    async customChanged(e, p) {
      global.isSectionLoading(p.id, 'show')
      let r = await global.customChanged(e, p, true);
      if (r)
        this.errorLog = r;
      this.updateTotalCart();
      this.disableOrderButton();
      global.isSectionLoading(p.id, 'hide')
    },

    async removeCartItem(p) {
      lib.write("\n\n[Remover produto]");
      lib.write(p);
      global.isSectionLoading(p.id, 'show')
      let apiMethodName = "RemoveProductFromShoppingCart";
      let errorMsg = "";
      try {
        let r = await axios.post(
          `/api/Business/RemoveProductFromShoppingCart`,
          null,
          {
            params: {
              userId: this.$store.state.user.id,
              shoppingCartId: this.$store.state.user.shoppingCart.id,
              shoppingCartProductId: p.cartProductId
            },
          }
        );
        lib.write("resposta:");
        lib.write(r.data);
        if (r?.data?.success) {
          this.$store.commit("removeFromCart", p.id);
          this.updateTotalCart();
          this.getTotalPallets();
          this.audit("Produto removido do cart: ", p.id);
        }
        else {
          let headerError = "Error removing from cart for shoppingCartId: " + this.$store.state.user.shoppingCart.id + " and shoppingProductId: " + p.color.associatedShopProductId + " Detail: ";
          this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
          this.audit("Tentativa de remover item do cart sem sucesso ", p.id);
        }
      } catch (error) {
        if (error.response) {
          errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
          lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
          this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
          lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
          this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
          lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
          this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
      }
      global.isSectionLoading(p.id, 'hide')
    },
    updateTotalCart() {

      console.log("updateTotalCart()")

      lib.write("\n\n[update total]")
      this.total = 0;
      this.$store.state.user.shoppingCart.cartItems.forEach((i) => {
        this.total += i.finalPrice ?? 0;
      });

      this.totalNoShipping = this.total
      this.total += this.totalShipping;

      console.log("new total: " + this.total)

    },

    getTotalPallets() {
      //total Paletes
      console.log("getTotalPallets()")

      this.disableShipping = false
      const isBoxExist = false

      this.$store.state.user.shoppingCart.cartItems.forEach((i) => {

        this.totalPallets += i.dose ?? 0;
        let dose = (i.dose) ? i.dose.toLowerCase().trim() : '';
        i.hasBoxes = this.hasBoxes(dose);

        //console.log(i.hasBoxes)

        // if (i.hasBoxes === true) {
        //   this.cartInterface("shippingDisabled", "getTotalPallets()")       
        // }

        // if (i.hasBoxes !== true) {
        //   this.cartInterface("shippingEnabled")       
        // }

      });

      // Check if ALL hasBoxes values are true
      const allHasBoxesTrue = this.$store.state.user.shoppingCart.cartItems.every((i) => i.hasBoxes);
    

      if  (this.userIsInternacional === true) {
        this.cartInterface("shippingDisabled");

      } else if (allHasBoxesTrue) {
        this.cartInterface("shippingDisabled");
      } else {
        this.cartInterface("shippingEnabled");
      }


      // Checks if the User is not Internacional, to enable Shipping
      // if (allHasBoxesTrue || this.userIsInternacional == 10) {
      //   this.cartInterface("shippingDisabled");
      // } else {
      //   this.cartInterface("shippingEnabled");
      // }

      // if (this.disableShipping === false) {
      //     this.cartInterface("shippingEnabled")          
      // }

    },

    disableOrderButton() {
      this.errorLog = "disableOrderButton()"
      this.submitOrderCheck = false
    },

    async getAddresses(isShippingPage) {
      let r = await axios.get(
        `/api/Business/GetCustomerContacts`,
        {
          params: {
            customerId: this.$store.state.user.customerId,
            languageId: this.$store.state.selectedLanguageId
          },
        }
      );
      lib.write("resposta das moradas:");
      lib.write(r.data);
      if (r?.data?.success) {
        this.addresses = r?.data.responseData?.list ?? [];
        this.selectedShippingAddressId = this.addresses[0].id

        if (isShippingPage) {
          await this.addressManager(this.addresses[0].id, "selectFirst", "updated");
          await this.addressManager(this.addresses[0].id, 'select', 'updated'); //Pass the ID of the First Address to help
          await this.GetShippingCostTable()
        }
      }
      else {
        let headerError = "Error removing from cart for shoppingCartId: " + this.$store.state.user.shoppingCart.id + " and shoppingProductId: " + p.color.associatedShopProductId + " Detail: ";
        this.errorLog = headerError + r.data.messageDetails ?? r.data.message;
        this.audit("Erro ao Listar Moradas do Utilizador ", p.id);
      }
    },

    async GetShippingCostTable() {

      console.log("GetShippingCostTable()")

      let t = await axios.get(
        `/api/Business/GetShippingCostTable`,
        {
          params: {
            languageId: this.$store.state.selectedLanguageId
          },
        }
      );
      lib.write("resposta da tabela de shipping:");
      lib.write(t.data);
      if (t?.data?.success) {
        this.tableShippingPrices = global.getShippingTable(t?.data.responseData ?? "")

        //alert(this.$store.state.user.shoppingCart.cartItems.length)

        if (this.$store.state.user.shoppingCart.cartItems.length !== 0) //Com o Cart Vazio, dá erro no totalShipping ParseFloat
        {
          this.totalPallets = global.totalDose(this.$store.state.user.shoppingCart.cartItems);
          this.rowShippingPrices = global.getPricesForShipping(this.totalPallets, this.tableShippingPrices)

          if (this.totalPallets !== 0) {
            this.totalShipping = parseFloat(global.getPricesForShippingByZoneId(this.addresses[0].address.addressAreaId, this.rowShippingPrices).replace(',', '.')) * this.totalPallets;
          }

          console.log("rowShippingPrices: " + this.rowShippingPrices)
          this.shippingPerPallet = this.totalShipping / this.totalPallets;
          this.updateTotalCart()
        }
      }
    },

    async placeOrderTest() {

         this.cartInterface("showErrorOrder");
      // this.orderErrorInfo = true; //shows the sucess info
        //this.cartSection = false; //hides the form
        //this.isLoadingPlacingOrder = false //shows the Waiting for processing 

},

    async placeOrder() {

      if (this.submitOrderCheck) { //check if submitOrder button is clickable
        let apiMethodName = "CreateShopPurchaseOrder";
        let errorMsg = "";
        this.isLoadingPlacingOrder = true
        lib.write("\n\n[concluir encomenda]");
        try {                    
          let r = await axios.post(`/api/Business/CreateShopPurchaseOrder`, null, {
            params: {
              shopId: this.$store.state.session.shopId,
              customerId: this.$store.state.user.customerId,
              shoppingCartId: this.$store.state.user.shoppingCart.id,
              withShipping: this.withShipping,
              customerContactId: this.selectedShippingAddressId,
              associatedNote: this.associatedNote,
              productExtraId: this.extra?.id,
              languageId: this.$store.state.selectedLanguageId
            },
          });

          if (r?.data?.success) {
            lib.write("\n\n[encomenda processada]")
            lib.write(r.data.responseData)
            this.orderCode = r?.data?.responseData;
            this.associatedNote = "";
            this.cartStepUI("step-03") //UI advance to stepping to ORDER
            let e = await axios.post(`/api/Session/ProcessPickUp`, null, {
              params: {
                shopId: this.$store.state.session.shopId,
                customerId: this.$store.state.user.customerId,
              },
            });

            let newId = 0;
            if (e?.data?.success) {
              newId = e.data.responseData;
              lib.write("\n\n[Pickup concluido com sucesso!]")
              this.audit("Encomenda finalizada", this.orderCode);
            }
            else {
              lib.write("\n\n[Erro ao processar pickup!]")
              this.audit("Erro ao finalizar encomenda - ProcessPickUp", e?.data?.messageDetails ?? e?.data?.message ?? this.orderCode);
            }

            this.$store.commit("emptyCart", newId ?? 0);
            this.total = 0;

            this.cartInterface("showInfoOrder");
          }
          else {
            let headerError = "Error placing order for shopId: " + this.$store.state.session.shopId + " and customerId: " + this.$store.state.user.customerId + " and shoppingCartId: " + this.$store.state.user.shoppingCart.id + " Detail: "
            this.errorLog = headerError + r.data.messageDetails ?? r.data.message;

            //alert(r.data.messageCode)

            //Invalid Price Code
            if (r.data.messageCode === '201')  {
              this.cartInterface("showErrorPrice");
              //this.disabledCartItemsMSG = r.data.messageCode //saves the error code, for splittin'
              this.getDisabledCartItems(r.data.message) //deals with the error code
              //this.$forceUpdate

            } else
              this.cartInterface("showErrorOrder");

            this.audit("Erro ao finalizar encomenda - CreateShopPurchaseOrder", r?.data?.messageDetails ?? r?.data?.message ?? this.$store.state.user.shoppingCart.id);
          }
        } catch (error) {
          if (error.response) {
            errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
            lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
            this.audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
          } else if (error.request) {
            lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
            this.audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
          } else {
            lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
            this.audit("Erro - configuração do pedido - " + apiMethodName, error.message)
          }
        }
      }
    },

    hasBoxes(dose) {
      return ((dose.endsWith('box')) || (dose.endsWith('boxes')) || (dose.endsWith('caixa')) || (dose.endsWith('caixa')) || (dose.endsWith('caixas')));
    },

    checkProductStatus(cartProductId) {
      return this.disabledCartItems.includes(cartProductId);
    },

    getDisabledCartItems(disabledCartItemsMSG) {
      console.log("getDisabledCartItems +" + disabledCartItemsMSG );
      this.disabledCartItems = [];
      const disabledCartItems = [];
      const numbersRegex = /\[(.*?)\]/;

      const numbersString = disabledCartItemsMSG.match(numbersRegex)?.[1];
      if (!numbersString) {
        return disabledCartItems;
      }

      const numbersArray = numbersString.split(';').map(Number);
      this.disabledCartItems.push(...numbersArray);

      return disabledCartItems;
    },

    
    goToSubCategories() {
      this.audit("Acesso Subcategoria");
      router.push({ name: "SubCategories" });
    },
    goToProducts() {
      this.audit("Acesso Produtos");
      router.push({ name: "Products" });
    },
    goToHome() {
      this.audit("Acesso Home");
      router.push({ name: "Home" });
    },
    goToUserArea() {
      this.audit("Acesso UserArea");
      router.push({ name: "UserArea" });
    },
    goToCart() {
      this.audit("Acedeu: Cart");
      router.push({ name: "Cart" });
    },

  },

  async mounted() {

    this.userIsInternacional = this.$store.state.user.internacionalUser //Internacional User

    if (!this.$store.state.isAuthenticated) router.replace({ name: "Login" });
    else {
      lib.write("\n\n[CartItems result]");
      lib.write(this.$store.state.user.shoppingCart.cartItems);
      this.cartSection = this.$store.state.user.shoppingCart?.cartItems?.length > 0;

      this.updateTotalCart();
      this.getTotalPallets();
      this.cartInterface("cart");

      if (this.userIsInternacional === true)
        {
          this.cartInterface("shippingDisabled") //Internacional User
        }

      console.log("mounted // after auth")

      let z = await axios.get(
        `/api/Business/GetAddressAreas`,
        {
          params: {
            userId: this.$store.state.user.id,
            languageId: this.$store.state.selectedLanguageId
          },
        }
      );

      await this.getAddresses(false);

      lib.write("resposta das zonas:");
      lib.write(z.data);
      if (z?.data?.success) {
        this.zones = z?.data.responseData ?? [];
      }

      await this.GetShippingCostTable()


    }
  },
}
</script>
