import axios from "axios";
import store from "./store";
import lib from "./library";
import router from "../router";

// UI Modifier 
const materialCreate = (id, color, index) => {
    let mTarget = "#material-selected-" + index;
    // let mName = name; //material  name
    let mColor = color; //material background color

    const target = document.querySelector(mTarget);
    const targetLabel = target.querySelector(".label");

    target.removeAttribute("class"); //changes the Button Color
    target.classList.add("button-select", mColor); //changes the Button Color
    targetLabel.innerHTML = getMaterialTranslated(id); //Changes the Label name
};

// UI Modifier
const productPanelShow = (id) => {
    let prodDetails = "prodDetails-" + id; //tableID in dom
    let prodSelection = "prodSelection-" + id; //tableID in dom

    let x = document.getElementById(prodDetails);
    let y = document.getElementById(prodSelection);

    if (x.style.display === "none") {
        x.style.display = "block";
    } else {
        x.classList.add(
            "animate__animated",
            "animate__fadeInUp",
            "animate__faster"
        );
        x.style.display = "none";
    }

    if (y.style.display === "none") {
        y.style.display = "block";
        y.classList.add(
            "animate__animated",
            "animate__fadeInDown",
            "animate__faster"
        );
    } else {
        y.style.display = "none";
    }
};

// UI Modifier
const updateColor = (rgb, colorName, id) => {
    let targetID = id;
    let mTarget = "#color-Modal-" + targetID;
    const target = document.querySelector(mTarget);

    if (!rgb) rgb = "255,255,255";

    lib.write("a cor é " + rgb + " - " + colorName + " - " + id);
    target.style.display = "none"; //close the Modal Window

    //the target Label ID
    let mTargetRGB = "#color-rgb-" + targetID;
    let mTargetLabel = "#color-label-" + targetID;

    document.querySelector(mTargetRGB).style.color = "rgb(" + rgb + ")";
    document.querySelector(mTargetLabel).innerHTML = colorName;
};

// Backend Modifier
const colorChanged = async (c, p, isCart) => {
    if (isCart) {
        let btnTrash = document.getElementById("isTrash-" + p.id);
        if (btnTrash !== undefined) {
            btnTrash.classList.add("disabled");
        }
    }

    lib.write(p, "produto a mudar cor");
    console.log(store.state.colors.find(x => x.id === p.color.id))
    updateColor(c.rgb, store.state.colors.find(x => x.id === p.color.id).name, isCart ? p.cartProductId : p.id); //UI Dropdown Modifier Call

    let colors = store.state.colors.map((element) => element);
    let colorName = await colors.find(x => x.id === p.color.id).name
    p.color = { code: c.code, id: c.id, name: colorName, rgb: c.rgb, associatedShopProductId: c.associatedShopProductId, minPrice: c.minPrice };


    // Color Message Observation Toggle - UI Element
    let infoBoxObs = "#infobox-color-" + p.id; //tableID in dom
    const target = document.querySelector(infoBoxObs);
    //if (c.obs == "") { target.style.display = "none"; } else { target.style.display = "flex"; }


    if (!isCart) {
        audit("Cor modificada no Cart ", "( " + p.id + " " + c.code + " " + c.name + " )" + " produto: " + p.id);
    }

    if (isCart) {
        let r = await updateTotalPrice(p);
        let btnTrash = document.getElementById("isTrash-" + p.id);
        if (btnTrash !== undefined) {
            btnTrash.classList.remove("disabled");
        }

        audit("Cor modificada no Cart ", "( " + p.id + " " + c.code + " " + c.name + " )" + " produto: " + p.id);
        return r;
    }


    lib.write(p, "produto com cor mudada");
};

const customChanged = async (e, p, isCart) => {
    if (e == null) {
        updateCustomChanged(lib.txt('noCustomSelected'), p.id); //UI Dropdown Modifier for null
        p.extra = null;
    }
    else {
        lib.write(p, "produto a mudar extra");
        updateCustomChanged(e.name, p.id); //UI Dropdown Modifier    
        p.extra = { id: e.id, name: e.name, description: e.description, obs: e.obs };
    }

    if (isCart) {
        let r = await updateTotalPrice(p);
        if (e != null)
            audit("Extra modificado no Cart ", "( " + e.name + " )" + " produto: " + p.id);
        return r;
    }
};

// TO-DO // UI Modifier
const updateCustomChanged = (customName, id) => {
    //alert(customName + "-" + id)
    let targetID = id;
    let mTarget = "#customize-Modal-" + targetID;
    const target = document.querySelector(mTarget);

    target.style.display = "none"; //close the Modal Window

    //the target Label ID
    let mTargetName = "#custom-name-" + targetID;

    document.querySelector(mTargetName).innerHTML = customName; //change the Label
};

// UI Modifier
const isColorModal = (targetID, action) => {
    let mTarget = "#color-Modal-" + targetID;
    const target = document.querySelector(mTarget);
    if (action == "open") { target.style.display = "flex"; }
    if (action == "close") { target.style.display = "none"; }
};

// UI Modifier
const isCustomModal = (targetID, action) => {
    let mTarget = "#customize-Modal-" + targetID;
    const target = document.querySelector(mTarget);
    if (action == "open") { target.style.display = "flex"; }
    if (action == "close") { target.style.display = "none"; }
};

// UI Modifier
const isPalletDropdown = (targetID, action) => {
    let mTarget = "#qt-paletsUnit-" + targetID;
    const target = document.querySelector(mTarget);
    if (action == "open") { target.style.display = "block"; }
    if (action == "close") { target.style.display = "none"; }
};

// UI Modifier
const isSectionLoading = (targetID, action) => {
    let mTarget = "#isLoading-" + targetID;
    const target = document.querySelector(mTarget);
    if (action == "show") { target.style.display = "flex"; }
    if (action == "hide") { target.style.display = "none"; }
};

// Backend // Get categories by parent
const getSubcategories = async (parentId, storeKey, selectedStoreKey) => {

    let apiMethodName = "getBusinessProductSubCategories";
    let errorMsg = "";
    try {
        let r = await axios.get(`/api/Business/getBusinessProductSubCategories`, {
            params: {
                userId: store.state.user.id,
                businessId: store.state.session.businessId,
                subCategoryId: parentId,
                languageId: store.state.selectedLanguageId
            },
        });

        if (r?.data?.success) {
            lib.write("\n\n[" + storeKey + " result]");
            lib.write(r);
            if (r.data?.responseData?.categoriesList && r.data.responseData.categoriesList.length > 0) {
                store.commit(storeKey, r.data.responseData.categoriesList);
                if (selectedStoreKey)
                    store.commit(selectedStoreKey, r.data.responseData.categoriesList[0].id);
            }
        }
        else {
            let headerError = "Error loading subcategories for parentId: " + parentId + " and businessId: " + store.state.session.businessId + " Detail: ";
            return headerError + r.data.messageDetails ?? r.data.message;
        }

    } catch (error) {
        if (error.response) {
            errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
            lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
            audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
            lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
            audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
            lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
            audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
    }
};

// Backend //
const palletChanged = async (p, dose, isCart) => {
    let quantity = getQuantityByPallets(dose, p);
    p.quantity = quantity.units;
    p.dose = dose;

    if (!isCart) {
        audit("Quantidade modificada na listagem de Produto ", dose + " (" + p.name + ")");
    }

    if (isCart) {
        let r = await updateTotalPrice(p);
        lib.write("Price: " + p.totalPrice);

        audit("Quantidade modificada na listagem de Cart ", dose + " (" + p.name + ")");

        return r;
    }
};

// update item in DB and update total price
const updateTotalPrice = async (p) => {
    let apiMethodName = "UpdateShoppingCartProductWithParameters";
    try {
        let r = await axios.post(
            `/api/Business/UpdateShoppingCartProductWithParameters`,
            null,
            {
                params: {
                    userId: store.state.user.id,
                    shoppingCartId: store.state.user.shoppingCart.id,
                    shoppingCartProductId: p.cartProductId,
                    quantity: p.quantity,
                    productMaterialId: p.material.id,
                    productColorId: p.color.id,
                    productExtraId: p.extra ? p.extra.id : null
                },
            }
        );

        if (r?.data?.success && r.data?.responseData) {
            p.totalPrice = r.data.responseData.totalPrice;
            p.finalPrice = r.data.responseData.finalPrice;
            p.extrasPrice = r.data.responseData.extrasPrice;
        } else {
            let headerError =
                "Error updating product quantity/color for userId: " +
                store.state.user.id +
                " and shoppingCartId: " +
                store.state.user.shoppingCart.id +
                " and shoppingCartProdcutId: " +
                p.cartProductId +
                " and quantity: " +
                p.quantity + " and productMaterialId: " + p.material.id +
                " and productColorId: " + p.color.id +
                " Detail: ";
            return headerError + r.data.messageDetails ?? r.data.message;
        }

    } catch (error) {
        if (error.response) {
            errorMsg = `HTTP Status ${error.response.status}: ${error.response.data}`
            lib.write(apiMethodName + ": " + errorMsg, "Erro na Resposta");
            audit("Erro - Resposta HTTP - " + apiMethodName, errorMsg);
        } else if (error.request) {
            lib.write(apiMethodName + ": " + error.request, "Erro resposta não recebida");
            audit("Erro - Sem resposta do pedido - " + apiMethodName, error.request)
        } else {
            lib.write(apiMethodName + ": " + error.message, "Erro na montagem do pedido");
            audit("Erro - configuração do pedido - " + apiMethodName, error.message)
        }
    }
}

const getMaterialTranslated = (id) => {
    console.log(store.state.materials.find(m => m.id === id))
    return store.state.materials.find(m => m.id === id).name
}

const getColorTranslated = (id) => {
    return store.state.colors.find(c => c.id === id).name
}

// Calculate units, boxes and pallets
const getQuantityByUnit = (units, rule) => {
    if (units === null || units == 0) return { units: 0, boxes: 0, pallets: 0 };

    return {
        units,
        boxes: Math.floor((units / rule.unitsPerBox) * 100) / 100,
        pallets: Math.floor((units / rule.unitsPerPallet) * 100) / 100,
    };
};

const getQuantityByPallets = (dose, p) => {
    let item = getPriceTableItemByPallets(p.tablePrices.tablePrices, dose)
    return {
        units: item.Quantity ?? 0,
        dose: dose ?? "0"
    };
}

const getPricesForShipping = (totalPallets, table) => {
    if ((totalPallets ?? 0) > 0) {
        let item = table.find(x => x.Description === totalPallets.toString());
        if (item == null || item == {})
            return table[table.length - 1];

        return item;
    }

    return null;
}

const getPricesForShippingByZoneId = (zoneId, prices) => {
    if ((zoneId ?? 0) > 0 && prices != null) {
        if (zoneId == 1)
            return prices.AreaA;
        else if (zoneId == 2)
            return prices.AreaB;
        else if (zoneId == 3)
            return prices.AreaC;
        else if (zoneId == 4)
            return prices.AreaD;
        else if (zoneId == 5)
            return prices.AreaE;
        else if (zoneId == 6)
            return prices.AreaF;
    }
}

const totalDose = (items) => {
    if (!items || items.length === 0) {
        return 0;
    }

    const total = items.reduce((sum, item) => {
        if (!item.dose) {
            return sum;
        } else if (typeof item.dose === 'string' && item.dose.includes('/')) {
            return sum + 0.5; // Tratar dose com '/' como 0.5
        } else if (typeof item.dose === 'string' && /\d+\s+\w+/i.test(item.dose)) {
            return sum;
        } else {
            return sum + parseFloat(item.dose);
        }
    }, 0);

    return Math.round(total); // Arredondar o resultado final sempre
};


const getQuantityByBox = (boxes, rule) => {
    if (boxes <= 0 || !rule) return { units: 0, boxes: 0, pallets: 0 };
    let units = Math.floor(rule.unitsPerBox * boxes * 100) / 100;
    return {
        units,
        boxes: boxes,
        pallets: Math.floor((units / rule.unitsPerPallet) * 100) / 100,
    };
};

// Get custom rule from API object
const getUnitsRule = (rules) => {
    if (!rules || rules.length == 0)
        return {
            unitsPerBox: 0,
            unitsPerPallet: 0,
        };

    let boxRule = rules.find((r) => r.name.trim().toLowerCase() == "box");
    let palletRule = rules.find((r) => r.name.trim().toLowerCase() == "pallet");
    return {
        unitsPerBox: boxRule ? boxRule.capacity : 0,
        unitsPerPallet: palletRule ? palletRule.capacity : 0,
    };
};

// Get Build the server path
const buildPath = (img) => {
    return ("https://store.plasticos-futura.com/products/" + img)
};

// Get Price table from json string
const getPriceTable = (data) => {
    let table = data ? JSON.parse(data) : [];
    let colorRow = table.pop();
    return { table, colorRow };
};

const getShippingTable = (data) => {
    let table = data ? JSON.parse(data) : [];
    table.pop();
    return table;
};

// Get Price table item by pallets
const getPriceTableItemByPallets = (data, dose) => {
    let table = getPriceTable(data).table;
    let item = table.find(x => x.UnitLoad === dose);

    return item ?? {};
};

// Get Price table item by quantity
const getPriceTableItemByQuantity = (data, quantity) => {
    let table = getPriceTable(data).table;
    let item = table.find(x => x.Quantity === quantity);

    return item ?? {};
};

// Add new audit record to DB
const audit = (action, detail) => {

    if (store.state.hasAudit) {
        let value = '-';

        if (detail)
            value = detail

        console.log("%cAudit: " + router.currentRoute._value.name + " : " + action + " : " + value, 'background-color:darkslategray; color: silver')

        axios.post(`/api/Session/RegisterSessionAudit`, null, {
            params: {
                page: router.currentRoute._value.name,
                action,
                value,
                sessionId: store.state.session.id,
            },
        });
    }
};

const addressManager = (id, operation, origin) => {

    lib.write("addressManager: " + id + " - " + operation + " - " + origin);

    const addressContainer = `address-${id}`; // Used template literals () to concatenate the idvariable into theaddressContainerstring, instead of concatenating using the+` operator.
    const addressDivs = document.querySelectorAll('[id^="address-"]');
    const targetAddressContainer = document.getElementById(addressContainer);
    const addressCheckBox = document.getElementById(`chkAddress-${id}`);

    const x = document.getElementById(`addressTrash-${id}`);
    const y = document.getElementById(`address-${id}`);

    if (operation === "buttonSelect") {


        const elements = document.querySelectorAll('[data-shipping="buttonSelect"]');

        // Loop through all elements and add the class "button-select-active" to the first one
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];

            element.classList.remove('button-select-active');

            if (i === 0) {
                element.classList.add('button-select-active');
            } else {
                element.classList.remove('button-select-active');
            }
        }


    }

    // Select the First Radio
    if (operation === "selectFirst") {
        const addressItems = document.getElementsByClassName("shipping-address-item");

        if (addressItems.length > 0) {
            // Find the first radio box within the first "shipping-address-item"
            const firstAddressItem = addressItems[0];
            const firstRadioButton = firstAddressItem.querySelector('input[type="radio"]');

            // Set the checked property to true
            if (firstRadioButton) {
                firstRadioButton.checked = true;
            }
        }
    }

    // UI Operation
    if (operation === "select") {
        const addressDivs = document.querySelectorAll('[id^="address-"]');
        const targetAddressContainer = document.getElementById(addressContainer);
        addressDivs.forEach((div) => {
            div.classList.remove("shipping-selected");
        });
        targetAddressContainer.classList.add("shipping-selected");
        addressCheckBox.checked = true;
    }

    // Delete Address
    if (operation === "delete") {

        //alert(`addressTrash-${id}`);
        //alert(addressContainer)
        // const e = document.querySelector(addressContainer);                
        // e.remove();

        if (x.style.display === "none") {
            x.style.display = "flex";
            //y.style.display = "none";
            x.className = '';
            x.classList.add("addressTrash-container", "p-2", "animate__animated", "animate__fadeInUp", "animate__faster");
        }
        else {
            x.style.display = "none";
        }
    }

    // cancel Delete Modal
    if (operation === "cancelDelete") {

        if (x.style.display === "flex") {
            x.className = '';
            x.classList.add("addressTrash-container", "p-2", "animate__animated", "animate__fadeOutDown", "animate__faster");
        } else {
            x.style.display = "none";
        }

    }

    // Delete was Confirmed by user
    if (operation === "confirmDelete") {

        const elementToRemove = document.getElementById(`address-${id}`);
        if (elementToRemove) { elementToRemove.remove(); }

        addressManager(1, "selectFirst")


    }

    if (operation === "addNewAddress") {

        // the form was hidden
        // this.newAddressForm = false //hides the FORM

    }


}

const cartStepUI = (stepName) => {

    const isStepVisible = document.getElementById('cartProgressSteps');

    if (isStepVisible) {

        const getAllSteps = document.querySelectorAll("[data-step^='step']");

        const addressDivs = document.querySelectorAll("[data-step='" + stepName + "']");

        const step01 = document.querySelector("[data-step='step-01']");
        const step02 = document.querySelector("[data-step='step-02']");
        const step03 = document.querySelector("[data-step='step-03']");
        const step02All = document.querySelectorAll("[data-step='step-02']");
        const step03All = document.querySelectorAll("[data-step='step-03']");


        // // Clear the Actives
        // addressDivs.forEach(addressDivs => {
        //     // Perform desired operations with each element
        //     addressDivs.classList.add("step-active");
        // });

        if (stepName === "step-01") {
            getAllSteps.forEach((div) => {
                div.classList.remove("step-active");
            });
            step01.classList.add("step-active");
        }

        if (stepName === "step-02") {
            step02All.forEach(step02All => {
                step02All.classList.add("step-active");
            });

            step03All.forEach(step03All => {
                step03All.classList.remove("step-active");
            });


        }

        if (stepName === "step-03") {

            step02All.forEach(step02All => {
                step02All.classList.add("step-active");
            });

            step03All.forEach(step03All => {
                step03All.classList.add("step-active");
            });

        }

    }

    //alert(stepName)
}


export default {
    materialCreate,
    productPanelShow,
    isColorModal,
    isCustomModal,
    isPalletDropdown,
    getSubcategories,
    colorChanged,
    customChanged,
    palletChanged,
    getQuantityByUnit,
    getQuantityByBox,
    getUnitsRule,
    isSectionLoading,
    buildPath,
    getPriceTable,
    getPriceTableItemByPallets,
    getPriceTableItemByQuantity,
    getQuantityByPallets,
    audit,
    cartStepUI,
    addressManager,
    getShippingTable,
    getPricesForShipping,
    getPricesForShippingByZoneId,
    totalDose,
    getColorTranslated,
    getMaterialTranslated
};
