<template>
  <div class="footer-wrapp">
    <div class="footer-back">
      <i class="fa fa-chevron-left" @click="hasHistory() ? $router.go(-1) : $router.replace('Categories')"></i>
    </div>

    <div class="d-flex flex-column text-center">
      <span class="footer-title">{{ lib.txt("bottomHelp") }}</span>
      <span class="footer-email">
        <i class="fa fa-envelope me-2"></i>{{ $store.state.email }}</span
      >
    </div>

    <!-- empty div-->
     <div class="footer-cart"></div>
  </div>
</template>

<script>
import texts from "../common/translation";
import lib from "../common/library"

export default {
  name: "Footer", 
  data() {
    return {
      texts,
      lib
    };
  },
  methods: {
      hasHistory() { 
        lib.write(window?.history?.state?.back != "/Login","Can Go back")
        return window?.history?.state?.back != "/Login" 
      }
  }
};
</script>
