<template>
  <div id="subcategories">

    

         <!-- Loading Status -->        
         <transition
          name="custom-classes"
          enter-active-class="animate__animated animate__fadeInDown animate__faster"
          leave-active-class="animate__animated animate__fadeOutUp animate__faster">
          <!-- Loading Bar -->
          <div class="loading-bar-01-wrapp" v-if="isLoading">
            <div class="loading-bar-01">
              <div class="loading-bar-anim-01"></div>
              <div class="loading-bar-anim-02"></div>
              <div class="loading-bar-anim-03"></div>
              <div class="loading-bar-anim-04"></div>
            </div>
          </div>
        </transition>     

      <ErrorPopup :errorMessage="errorMessage" :errorLog="errorLog" />


    <div class="header-banner-02">
      <!-- Header Component-->
      <div class="header-container">
        <!-- Logo -->
        <div
          id="branding"
          class="branding branding-light"
          @click="goToHome">
        </div>

        <!-- Top Tools / User Area / Cart View -->
        <div class="header-options-wrapp sticky-top">
          <!-- User Area Icon-->
          <div class="text-end">
            <div
              id="iconUserArea"
              @click="goToUserArea"
              class="icon-user-area icon-user-dark">
              <i class="fa fa-user"></i>
              <div class="icon-animate"></div>
            </div>
          </div>

          <div class="footer-cart" @click="goToCart">
            <div>
              <div class="cart-item-count">
                {{ $store.state.user.shoppingCart?.cartItems?.length }}
              </div>
              <img src="/products/editable/cart-svgrepo-com-b.svg" />
            </div>
          </div>
        </div>
      </div>

      <!-- Prod Nav-->
      <div class="prod-nav-wrapp">

        <div class="prod-nav-mainCat">
          <div
            v-for="c in $store.state.categories"
            :key="c.id"
            :class="c.id == $store.state.selectedCategoryId ? 'active' : ''">
            <span :class="{ 'category-disabled': c.showcase.stateId == 1 }" @click="categoryChanged(c.id)">{{ c.name }}</span>
          </div>
        </div>

        <div class="prod-nav-subCat">
          <div class="carousel-item-list h-100">
            <div
              class="subcategory-item"
              v-for="s in $store.state.subcategories"
              :key="s.id"
              :class=" s.id == $store.state.selectedSubcategoryId ? 'active' : '' " @click="subcategoryChanged(s.id)">
              {{ s.name }} <i class="fa fa-chevron-down d-md-none d-sm-none d-xl-none "></i>
            </div>
          </div>       
        </div>
      </div>
    </div>

    <!-- Card List -->
    <div id="ProductList">
      <div class="background-clip"></div>

      <div class="prod-wrapp">
        <!-- Card-->
        <template v-for="n in $store.state.productCategories" :key="n.id">
          <div class="category-card card-compact" @click="goToProducts(n.id)">
            <img :src="buildPath(n.photoMainPath)" alt="" />
            <div class="category-title">{{ n.name }}</div>
          </div>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ErrorPopup from './PopUpMsg';
import router from "../router";
import lib from "../common/library"
import global from "../common/global"
import texts from "../common/translation"
import Footer from './Footer';


export default {
  name: "Categories",
  components: { ErrorPopup, Footer },
  data() {
    return {
      audit: global.audit,
      getSubcategories: global.getSubcategories,
      buildPath: global.buildPath,
      texts,
      lib,
      isLoading: false, //Loader Status

      errorMessage: '', //errorBox
      errorLog: '' //errorBox
    };
  },
  methods: {
    async categoryChanged(id) {

      this.audit("Categoria Seleccionada", id);
      this.isLoading = true;
      //this.errorMessage = "errorMessage(" + id + ")";
      this.errorLog = "errorLog(" + id + ")";
      this.$store.commit("selectedCategory", id);
      this.$store.commit("subcategories", []);
      this.$store.commit("productCategories", []);
      
      let r = await this.getSubcategories(this.$store.state.selectedCategoryId, "subcategories", "selectedSubcategory");
      if (r)
      this.errorLog = r;

      this.isLoading = false; //Loader Status
    },
    async subcategoryChanged(id) {

      this.audit("Subcategoria Seleccionada", id);
      this.isLoading = true;
      this.errorLog = "errorLog subcategoryChanged(" + id + ")";
      this.$store.commit("selectedSubcategory", id);
      this.$store.commit("productCategories", []);

      let r = await this.getSubcategories(this.$store.state.selectedSubcategoryId, "productCategories", null);
        if (r)
        this.errorLog = r;
          
      this.isLoading = false //Loader Status
    },      
    goToProducts(productCategoryId) {
      this.audit("Categoria de Produtos escolhida", productCategoryId);
      this.$store.commit("selectedProductCategory", productCategoryId);
      router.push({ name: "Products" });
    },
    goToCategories() {
      this.audit("Acedeu: Categorias");
      router.push({ name: "Categories" });
    },
    goToUserArea() {
      this.audit("Acedeu: Area de Utilizador");
      router.push({ name: "UserArea" });
    },
    goToCart() {
      this.audit("Acedeu: Cart");
      router.push({ name: "Cart" });
    },
    goToHome() {
      this.audit("Acedeu: Pagina de Inicio");
      router.push({ name: "Home" });
    },
  },
  async mounted() { 

    if (!this.$store.state.isAuthenticated) router.replace({ name: "Login" });
    else if (this.$store.state.selectedCategoryId != null) {
      this.isLoading = true; //Loader Status    
      await this.getSubcategories(this.$store.state.selectedCategoryId, "subcategories", "selectedSubcategory");
      await this.getSubcategories(this.$store.state.selectedSubcategoryId, "productCategories", null);     
      this.isLoading = false; //Loader Status
    }
  },
};
</script>

<style>
#login .form-inputs {
  padding-bottom: 10px;
}

#login .form-inputs label {
  padding-right: 10px;
}
</style>
