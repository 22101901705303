const text = {
  reservedArea: { pt: "Área Reserada", en: "Reserved Area" },
  customerlogin: { pt: "Autenticação", en: "Customer Login" },
  email: { pt: "Email", en: "Email" },
  password: { pt: "Senha de acesso", en: "Password" },
  login: { pt: "Entrar", en: "Login" },
  loginBottomInfo: { pt: "NA ÁREA RESERVADA O UTILIZADOR PODE EFETUAR ENCOMENDAS E TEM ACESSO EXCLUSIVO AOS PRODUTOS E PREÇOS", en: "IN THE RESERVED AREA THE CUSTOMER CAN PLACE ORDERS AND HAS EXCLUSIVE ACCESS TO PRODUCTS AND PRICES", },
  welcome: { pt: "Seja Bem Vindo", en: "Welcome" },
  bottomHelp: { pt: "precisa de ajuda ?", en: "do you need help ?" },
  chooseMaterial: { pt: "Escolha o seu material", en: "Choose your material" },
  units: { pt: "Unidades", en: "Units" },
  columnColorlessPrice: { pt: "Preço sem cor </br> €/1000", en: "Colorless Price  </br>  €/1000", },
  availableColors: { pt: "Cores Disponiveis", en: "Available Colors" },
  quantity: { pt: "Quantidade", en: "Quantity" },
  color: { pt: "Cor", en: "Color" },
  pricePer1000: { pt: "Preço €/1000", en: "Price €/1000" },
  price: { pt: "Preço", en: "Price" },
  boxes: { pt: "Caixas", en: "Boxes" },
  dose: { pt: "Quantity<br>(Pallets/Boxes)", en: "Quantity<br>(Pallets/Boxes)" },
  pallets: { pt: "Paletes", en: "Pallets" },
  baseColorText: { pt: "Cor aplicável apenas à base", en: "Color applicable to base only", },
  taxesInfo: { pt: "O valor atual não inclui taxas e despesas de envio", en: "To the presented value is going to be added shipping and taxes", },
  placeOrder: { pt: "Concluir Encomenda", en: "Place Order" },
  orderSuccess: { pt: "O seu pedido de encomenda foi enviado com sucesso!\nSeremos breves a responder.", en: "Your order request has been sent successfully!\nWe'll be brief to answer.", },
  orderConfirmation: { pt: "Confirmo que validei todos os items no carrinho de compras e desejo prosseguir.", en: "I confirm that I have validated all items in the shopping cart and wish to proceed.", },
  orderNumber: { pt: "A sua encomenda é o nrº", en: "Your order number is" },
  orderInfo: { pt: " O seu pedido de encomenda será enviado para departamento de vendas", en: "Your order request will be sent to the sales department", },
  processingOrder: { pt: "Processar Encomenda", en: "Processing Order" },
  colorsRequest: { pt: " - preço sob consulta, envie o seu pedido para", en: " - price on request, send your request to", },
  otherColors: { pt: "Outras cores", en: "Other colors" },
  userProfile: { pt: "perfil", en: "user profile" },

  processingDelivery: { pt: "A processar", en: "Processing" },
  sentDelivery: { pt: "Enviada", en: "Sent" },
  finishedDelivery: { pt: "Entregue", en: "Delivered" },

  closedDelivery: { pt: "Terminada", en: "Closed" },
  receivedDelivery: { pt: "Recebida", en: "Received" },
  canceledDelivery: { pt: "Cancelada", en: "Canceled" },
  inactiveDelivery: { pt: "Inativa", en: "Inactive" },
  activeDelivery: { pt: "Ativa", en: "Active" },
  pendingDelivery: { pt: "Pendente", en: "pending" },


  favorites: { pt: "favoritos", en: "favorites" },
  favoriteRemoving: { pt: "A remover o favorito", en: "Removing this favorite" },
  previousOrders: { pt: "histórico", en: "previous orders" },
  customerNr: { pt: "Utilizador Nr.", en: "Customer Nr." },
  firstName: { pt: "Primeiro Nome", en: "First Name" },
  lastName: { pt: "Apelido", en: "Last Name" },
  phoneNumber: { pt: "Telefone", en: "Phone Number" },
  company: { pt: "Empresa", en: "Company" },
  location: { pt: "Localidade", en: "Location" },
  postalCode: { pt: "Código Postal", en: "Postal Code" },
  filter: { pt: "Filtro", en: "Filter" },
  byYear: { pt: "Por Ano", en: "By Year" },
  byReference: { pt: "Por Referência / Nº Enc / Ano", en: "By Reference / Order Nr. / Year" },
  reference: { pt: "Referência / Nome", en: "Reference / Name" },
  search: { pt: "Procurar", en: "Search" },
  searchAll: { pt: "Mostrar todas as Encomendas", en: "Find all References Ordered" },
  noCartItems: { pt: "De momento não tem nenhum produto adicionado ao carrinho.", en: "There are currently no products added to your cart.", },
  noResults: { pt: "Sem resultados de encomendas ou ainda não ten encomendas para mostrar... Use a pesquisa para refinar os resultados.", en: "No results or there is no orders to show... Use the search to find your order.", },
  noFavorites: { pt: "De momento não tem nenhum produto nos favoritos, por enquanto! ", en: "You don't have any favorite product, yet! ", },
  addingToFavorites: { pt: "A adicionar como favorito!", en: "Adding to favorites!" },
  logout: { pt: "Sair", en: "Logout" },
  favoriteDuplicate: { pt: "Já existe este produto nos seus favoritos!", en: "This product is already a favorite" },
  tablePriceUnitType: { pt: "American Pallet", en: "American Pallet" },
  tablePriceUnits: { pt: "Unidades", en: "Units" },
  cartMessageFromUser: { pt: "Pode deixar um comentário sobre a sua encomenda, se necessário", en: "Leave us note about your order, if needed" },
  alreadyInCart: { pt: "Já foi adicionado ao Carrinho!", en: "Already in Cart" },

  loadingMessage: { pt: "A carregar", en: "Loading" },
  loadingMessageSubmit: { pt: "A enviar pedido", en: "Sending request" },
  
  orderNeedToCancel01: { pt: "Precisa de Cancelar esta encomenda ?", en: "Need to cancel your order ?" },
  orderNeedToCancel02: { pt: "A sua encomenda será cancelada imediatamente após este pedido.", en: "Your order will be canceled promptly as per your request." },
  orderNeedToCancel03: { pt: "Por favor, descreva-nos a razão do cancelamento desta encomenda.", en: "Please provide us a description for why are you cancelling this order." },
  orderNeedToCancel04: { pt: "Esta encomenda foi cancelada", en: "This order was cancelled!" },
  orderNeedToCancel05: { pt: "Se tiver alguma dúvida respeitante aos nossos produtos, por favor aconselhe-se connosco.", en: "If you have any other questions concerning our products, please advise with us." },
  orderCancelBTN: { pt: "Cancelar encomenda", en: "Cancel order now" },
  orderErrorInfo: { pt: "Encomenda cancelada! Ocorreu um erro ao processar a sua encomenda.", en: "Order cancelled!! There was an error processing your order." },
  orderPriceChange: { pt: "Preços Alterados", en: "Preços Alterados" },



};

export default text;
