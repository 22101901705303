import { createWebHistory, createRouter } from "vue-router";

import Categories from "@/components/Categories.vue";
import SubCategories from "@/components/SubCategories.vue";
import Products from "@/components/Products.vue";
import Login from "@/components/Login.vue";
import Home from "@/components/Home.vue";
import Cart from "@/components/Cart.vue";
import UserArea from "@/components/UserArea.vue";
import ProductDetail from "@/components/ProductDetail.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Categories",
    name: "Categories",
    component: Categories,
  },
  {
    path: "/SubCategories",
    name: "SubCategories",
    component: SubCategories,
  },
  {
    path: "/Products",
    name: "Products",
    component: Products,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/Cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/UserArea",
    name: "UserArea",
    component: UserArea,
  },
  {
    path: "/viewProduct/:productId",
    name: "ProductDetail",
    component: ProductDetail,
    props: true 
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;